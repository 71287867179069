import React from "react";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import {Route} from 'react-router-dom';
import rasterizeHTML from 'rasterizehtml';
import firebase, {base} from './firebase.js';
import mmnt from 'moment';
import geolib from 'geolib';
import fsm from 'fuzzy-string-matching';
import Moment from 'react-moment';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import { ShareButtons, generateShareIcon } from 'react-share';
import AddToCalendar from 'react-add-to-calendar';
import Gravatar from 'gravatar';

import HangEdit from './hangedit.js';
import HangCrew from './hangcrew.js';
import HangChat from './hangchat.js';
import HangLink from './hanglink.js';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

const storage = firebase.storage().ref();

var hangHeader = {
  position: "relative",
  // backgroundColor: "#ec008c",
  margin: "0",
  color: "#fff",
  fontWeight: "300",
  padding: "1rem 5rem 1rem 1rem",
  cursor: "pointer",
  fontFamily: "'Poppins', sans-serif",
  boxSizing: "border-box",
  borderTopLeftRadius: "0.5rem",
  borderTopRightRadius: "0.5rem",
  zIndex: "1",
}

var hangHeaderTitle = {
  fontSize: "1.25rem",
  fontWeight: "600",
  lineHeight: "1",
  marginBottom: "0",
  textTransform: "capitalize",
  fontFamily: "'Poppins', sans-serif",
}

var hangMonth = {
  fontSize: "0.75rem",
  display: "inline-flex",
  alignContent: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingBottom: "0.45rem"
}

var hangYear = {
  fontSize: "0.25rem",
  display: "inline-flex",
  alignContent: "center",
  justifyContent: "space-between",
  width: "100%"
}

var hangWeekday = {
  fontSize: "0.75rem",
  display: "inline-flex",
  fontWeight: "800",
  alignContent: "center",
  justifyContent: "space-around",
  width: "100%"
}

var hangDay = {
  fontFamily: "Josefin Sans, sans-serif",
  fontWeight: "bold",
  fontSize: "1.6rem",
  display: "inline-flex",
  alignContent: "center",
  justifyContent: "space-between",
  width: "100%"
}

class HangItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inhang: false,
      key: '',
      hang: '',
      uid: '',
      shortUrl: '',
      mutualFriends: 0,
      openCalendar: false,
      valid: false,
      visibility: 'hide'
    }
    this.inHang = this.inHang.bind(this);
    this.joinHang = this.joinHang.bind(this);
    this.localHangChange = this.localHangChange.bind(this);
    //this.getMutualFriends = this.getMutualFriends.bind(this); /* Deprecated by Facebook :( */
    this.handleShareButton = this.handleShareButton.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.getPlace = this.getPlace.bind(this);
  }

  getPlace = (lat,lng) => {
    if(lat && lng){
      let maploc = new window.google.maps.LatLng(lat,lng);
      let mapdom = ReactDOM.findDOMNode(this.refs.map);

      if(mapdom){
        let map = new window.google.maps.Map(mapdom, {
            center: maploc,
            zoom: 15
          });

        if(this.props.hang.place){
          let service = new window.google.maps.places.PlacesService(map);
          service.getDetails({
              placeId: this.props.hang.place
          }, (result, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if(result.photos && result.photos.length > 0){
                var placePhotoUrl = result.photos[0].getUrl({maxWidth:630});
                this.setState({placeimg: placePhotoUrl});
              }
            }
          });
        }else{
          let service = new window.google.maps.places.PlacesService(map);
          service.nearbySearch({ location: maploc, radius: '1' },
          (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              if(results[1]){
                if(results[1].photos && results[1].photos.length > 0){
                  var placePhotoUrl = results[1].photos[0].getUrl({maxWidth:640});
                  this.setState({placeimg: placePhotoUrl});
                }
                this.setState({placename: results[1]['name']});
              }
            }
          });
        }
      }
    }
  }

  toggleEdit = () => {
    this.setState({ edit : !this.state.edit });
    this.setState({ showdesc: !this.state.showdesc });
  }

  toggleInfo = () => {
    if(!this.state.showdesc){
      ReactGA.event({category: 'User', action: 'Looking at Hang Info'});
    }
    this.setState({ showdesc: !this.state.showdesc });
  }

  joinHang = (hang, user, uid) => {
    //Add Member to Hang
    let name = this.props.username ? this.props.username : this.props.user.displayName;
    let userphoto =  this.props.userphoto ? this.props.userphoto : Gravatar.url(this.props.user.email, {s: '100', r: 'x', d: 'retro'}, true);
    const member = {
      uid: this.props.user.uid,
      user: name,
      userphoto: userphoto,
    }
    const userRef = firebase.database().ref(`/members/`);
    userRef.orderByChild("uid").equalTo(uid).once('value', (snapshot) => {
      if (snapshot.exists()) {
        var user = snapshot.val();
        let key = Object.keys(user)[0];
        const userHangRef = firebase.database().ref(`/members/${key}/hangs`);
        userHangRef.orderByChild("hang").equalTo(key).once('value', (snapshot) => {
          if (snapshot.exists()) {
            console.log('Already joined this hang');
          }else{
            userHangRef.push({hang});
            console.log('Hang Added To User!');
            const invitedRef = firebase.database().ref(`/members/${key}/invite`);
            invitedRef.orderByChild("hangid").equalTo(hang).once('value',
            (snapshot) => {
              if (snapshot.exists()) {
                let invkey = Object.keys(snapshot.val())[0];
                invitedRef.ref.child(invkey).remove();
              }
            });
            return;
          }
        });
      }
    });
    const crewRef = firebase.database().ref(`/hangs/${hang}/crew/`);
    crewRef.orderByChild("uid").equalTo(uid).once('value', function(snapshot){
      if (snapshot.exists()) {
        console.log('already added to hang');
      }else{
        crewRef.push(member);
      }
    });
    const hangRef = firebase.database().ref(`/hangs/${hang}`);
    //Add Joiner to Host Crew
    hangRef.once('value', function(snapshot){
      if (snapshot.exists()) {
        let hang = snapshot.val();
        const usersRef = firebase.database().ref(`/members/`);
        usersRef.orderByChild("uid").equalTo(hang['uid']).once('value', (snapshot) => {
          var key = Object.keys(snapshot.val())[0];
          const crewRef = firebase.database().ref(`/members/${key}/crew/`);
          crewRef.orderByChild("uid").equalTo(uid).once('value', (snapshot) => {
            if (snapshot.exists()) {
              console.log('already in crew');
            }else{
              crewRef.push(member);
            }
          });
        });
      }
    });
    this.localHangChange(hang.key);
    this.setState({ inhang: true });
    ReactGA.event({category: 'User', action: 'Hang Joined (Reg User)'});
  }

  inHang(id) {
    if(!this.props.public || this.props.public === false){
      if( this.props.hang.uid === this.props.user.uid ){
        this.setState({ inhang: true });
      }else{
        const crewRef = firebase.database().ref(`/hangs/${id}/crew/`);
        crewRef.orderByChild("uid").equalTo(this.props.user.uid).once('value', (snapshot) => {
          if (snapshot.exists()) {
            this.setState({ inhang: true });
          }else{
            this.setState({ inhang: false });
          }
        });
      }
    }
  }

  removeHang(id) {
    ReactGA.event({category: 'User', action: 'Hang Removed By Owner'});
    base.remove(`/hangs/${id}`);
    this.props.onHangChange(id);
  }

  localHangChange() {
    var hangid = this.state.key;
    const hangRef = firebase.database().ref(`/hangs/${hangid}`);
    hangRef.once('value', (snapshot) => {
       let newhang = snapshot.val();
       this.setState({ hang: newhang });
     });
    this.inHang(hangid);
  }

  handleShareButton(url) {
    ReactGA.event({category: 'User', action: 'Hang URL Share Opened'});
    this.props.openPopupBox(url);
  }

  toggleCalendar() {
    if( this.state.openCalendar ){
      this.setState({ openCalendar: false });
    }else{
      this.setState({ openCalendar: true });
    }
  }

  // getMutualFriends(user, uid, token){
  //   console.log('getting mutual friends');
  //   var getfbid = async () => {
  //     return new Promise((resolve, reject) => {
  //       const usersRef = firebase.database().ref(`/members/`);
  //       usersRef.orderByChild("uid").equalTo(uid).once('value', (snapshot) => {
  //         if (snapshot.exists()) {
  //           var u = snapshot.val();
  //           Object.entries(u).map((member) => {
  //               console.log('got fbid:'+ member[1].fbid);
  //               resolve(member[1].fbid);
  //               return member[1].fbid;
  //           });
  //         }
  //         return;
  //       });
  //     });
  //   };

  // var getGraphRequest = async () => {
  //   var fbid = await getfbid();
  //   return new Promise((resolve, reject) => {
  //     console.log(token);
  //     if(token){
  //       graph.get(fbid+'?fields=context.fields(mutual_friends)&access_token='+token, (err, res) => {
  //           console.log(res.context.mutual_friends);
  //           if(!err){
  //             if(res.context.mutual_friends){
  //               resolve(res.context.mutual_friends.summary.total_count);
  //             }else{
  //               resolve(0);
  //             }
  //           }else{
  //             throw err;
  //           }
  //       });
  //     }
  //   });
  // };
  //
  // var friends = async () => {
  //   var request = await getGraphRequest();
  //   return new Promise((resolve, reject) => {
  //     resolve(request);
  //   });
  // }
  //
  // return friends();

  //}

  componentWillMount = (result) => {

    // var promise = this.getMutualFriends(this.props.user, this.props.hang.uid, this.props.token);
    //
    // promise.then((result) => {
    //   console.log(result);
    //   this.setState({ mutualFriends:result });
    //   if( result > 0 ){
    //     this.setState({ visibility: 'show' })
    //   }
    // }).catch((err) => console.log("rejected:", err));
    if(this.props.hang.key){
      this.setState({ key: this.props.hang.key });
      this.inHang(this.props.hang.key);
    }else{
      this.setState({ key: this.props.id });
      this.inHang(this.props.id);
    }
  }

  checkMemberStatus = () => {
    if(this.props.user && this.props.user.uid && this.props.hang.uid !== this.props.user.uid){
      const userRef = firebase.database().ref(`/members/`);
      userRef.orderByChild("uid").equalTo(this.props.user.uid).once('value', (snapshot) => {
        if (snapshot.exists()) {
          var member = snapshot.val();
          let key = Object.keys(member)[0];
          member = member[key];
          let userHangs = member.hangs;
          if(userHangs && Object.entries(userHangs).length > 0){
            let match = Object.entries(userHangs).find(obj => {
              return obj[1].hang === this.props.id;
            });
            if(match && match[1].status === 'valid'){
              this.setState({valid: true});
              return;
            }
          }
        }
      });
    }
  }

  renderHangItem = () => {
    var canvas = ReactDOM.findDOMNode(this.refs.canvas);
    var detail = ReactDOM.findDOMNode(this.refs.detail);
    rasterizeHTML.drawHTML(detail.innerHTML,canvas);
  }

  doesFileExist = (urlToFile) => {
    var xhr = new XMLHttpRequest();
    xhr.open('HEAD', urlToFile, false);
    xhr.send();
    if (xhr.status === "404") {
        return false;
    } else {
        return true;
    }
  }

  saveImg = () => {
    var canvas = ReactDOM.findDOMNode(this.refs.canvas);

    // let setMeta = (url) => {
    //   if(url){
    //     console.log(url);
    //     var meta = document.querySelector('meta[property="og:image"]');
    //     meta.content = url;
    //     document.getElementsByTagName('head')[0].appendChild(meta);
    //     document.getElementById("img").src = url;
    //     console.log(meta);
    //   }
    // }

    let onResolve = (url) => {
      console.log("Image found. Saved to " + url);
      return;
    }

    let onReject = (error, func) => {
      console.log(error);
      func.on('state_changed', snapshot => {
        }, snapshot => {
          console.error("Unable to save image.");
        }, () => {
          let url = func.snapshot.downloadURL;
          console.log("Image found. Location here: " + url);
          return;
      });
    }

    canvas.toBlob(blob => {
      var name = this.props.hang.hash + ".png";
      var f = storage.child("images/" + name);
      var task = f.put(blob);
      storage.child("images/" + name).getDownloadURL()
      .then(
        function(url) {
          onResolve(url);
        })
      .catch(
        function(error) {
        onReject(error, task);
      });
    });
  };

  leaveHang(hang, uid) {
    console.log(`/hangs/${hang}/crew/${uid}`);
    base.remove(`/hangs/${hang}/crew/${uid}`);
    this.localHangChange(hang);
  }

  componentDidMount = (result) => {
    const hangRef = firebase.database().ref(`/hangs/${this.state.key}`);

    hangRef.once('value', (snapshot) => {
      let newhang = snapshot.val();
      this.setState({
        hang: newhang
      });
    });

    let crewarr = [];

    if(this.props.crew){
      let crew = Object.entries(this.props.crew);
      crew.map((item) => {
        crewarr.push(item[1].uid);
      });
    }

    if(this.props.user && this.props.hang.crew){
      let hangcrew = Object.entries(this.props.hang.crew);
      hangcrew.map((item) => {
        if(item[1].uid === this.props.user.uid){
          this.setState({crewkey: item[0]});
        }
      });
    }

    if( this.props.public ||
        crewarr.indexOf(this.props.hang.uid) !== -1 ||
        this.props.hang.uid === this.props.user.uid ||
        this.props.hang.visibility === 'public' ||
        this.state.inhang ||
        this.props.detail ||
        this.props.invited
      ){
      this.setState({ visibility: 'show' })
    }

    if(this.props.detail){
      //setTimeout(this.renderHangItem, 500);
      //setTimeout(this.saveImg, 1000);
    }

    if(this.props.geoReady
      && this.props.hang.lat
      && this.props.hang.lng){
      this.getPlace(this.props.hang.lat,this.props.hang.lng);
      let distance = geolib.getDistance(
        {latitude: this.props.geoReady.lat, longitude: this.props.geoReady.lng},
        {latitude: this.props.hang.lat, longitude: this.props.hang.lng}
      );
      distance = (distance/1000) * 0.621371;
      distance = distance.toFixed(1);
      this.setState({distance});
    }
  }

  componentDidUpdate = () => {
    this.checkMemberStatus();
  }

  // food-drink
  // arts-crafts
  // music-concerts
  // trails-views
  // stage-screen
  // sports-fitness
  // help-giving
  // words-stories
  // games-trivia

  mapCategory = (str) => {
    switch(str) {
    case 'food-drink':
        return 'Food & Drink';
    case 'arts-crafts':
        return 'Arts & Crafts';
    case 'music-concerts':
        return 'Music & Concerts';
    case 'trails-views':
        return 'Trails & Views';
    case 'stage-screen':
        return 'Stage & Screen';
    case 'sports-fitness':
        return 'Sports & Fitness';
    case 'help-giving':
        return 'Help & Giving';
    case 'words-stories':
        return 'Words & Stories';
    case 'games-trivia':
        return 'Games & Trivia';
    default:
        break;
    }
  }

  justify(str) {
    let newstr = [];
    str.split('').map((ltr) => {
      newstr.push(<span>{ltr}</span>);
    })
    return newstr;
  }

  render() {
    let imgHeight = '';
    if(this.props.detail){
      imgHeight = "300px";
    }
    if(this.state.edit){
      imgHeight = "525px";
    }
    if(!this.state.edit && !this.state.edit){
      imgHeight = "250px";
    }

    let imgUrl = '';
    if(this.state.edit){
      //Do nothing;
    }else if(this.props.hang.image){
      imgUrl = this.props.hang.image;
    }else{
      imgUrl = this.state.placeimg;
    }

    let hangImage = {
      backgroundImage: "url('"+imgUrl+"')",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      overflow: "hidden",
      minHeight: imgHeight,
      height: "auto !important",
      height: imgHeight,
    }

    var bkgdColor = "#f41c21";

    if( this.props.public && !this.props.user ){
      bkgdColor = "#34b6ee";
    }

    var hangDate = {
      background: bkgdColor,
      color: "#fff",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "0",
      right: "0",
      width: "36.5px",
      height: "100%",
      padding: "0 1rem",
      textTransform: "uppercase",
      textAlign: "center",
      borderCollapse: "collapse",
      borderTopRightRadius: "0.5rem",
    }

    var baseUrl = window.location.protocol + "//" + window.location.host;
    var hangLink = '/hang/'+this.props.hang.hash;
    var shareUrl = 'https://invite.hngrng.com/hangs/'+this.props.hang.hash;

    let event = {
        title: this.props.hang.title,
        description: this.props.hang.title+" with "+this.props.hang.user+" (powered by Hangerang)",
        location: this.props.hang.address,
        startTime: this.props.hang.datetime,
        endTime: mmnt(this.props.hang.datetime).add(2, 'hours')
    }

    let icon = { textOnly: 'none' };

    let count = 0;
    let limit = 99999;

    if(this.props.hang.crew){
      count = Object.entries(this.props.hang.crew).length;
    }

    if(this.props.hang.limit){
       limit = this.props.hang.limit;
    }

    let cardplace = [
      "ChIJW7YbsShzIocRbiWWQ_1exvg",
      "ChIJ0_erjsoKIocRTOcqAP9wWl4",
      "ChIJX5x3fjJzIocRe8i9FVZwMHk",
      "ChIJmwx0XKcNIocRYL89RbkM5wM",
      "ChIJ7bSaW2sLIocR-xzzjD7eo9w",
      "ChIJC8hstL8MIocRqbk6wNuHqLM",
      "ChIJx4mQORMNIocRh8jLDkkYnBw"
    ]

    let incard = cardplace.find((element) => {
      if(element === this.props.hang.place){
        return true;
      }else{
        return false;
      }
    });

    let Hang =
      <span>
        <span ref="detail">
        <table width="100%" style={hangHeader} className={'hang-header'}>
          <tbody>
          <tr>
            <td>
              <h2 style={hangHeaderTitle}>{this.props.hang.title}</h2>
              <span className="hang-placetime">
                <Moment format="hh:mm a" className="hang-time">{this.props.hang.datetime}</Moment> @ {this.props.hang.placename || this.state.placename}
              </span>
              <div>
                <div className={'capsule small capitalize white'}>
                  {this.props.hang.visibility}
                </div>
                {this.props.hang.category &&
                  <div className={'capsule small '+this.props.hang.category}>
                    {this.mapCategory(this.props.hang.category)}
                  </div>}
                {this.props.hang.limit &&
                  <div className='capsule small white'>
                    Limit {this.props.hang.limit} People
                </div>}
              </div>
            </td>
            <td>
              <table style={hangDate}>
                <tbody>
                <tr>
                  <td>
                    <div className="hang-weekday" style={hangWeekday}>
                    {this.justify(mmnt(this.props.hang.datetime).format('ddd'))}
                    </div>
                    <div className="hang-month" style={hangMonth}>
                      {this.justify(mmnt(this.props.hang.datetime).format('MMM'))}
                    </div>
                    <div className="hang-day" style={hangDay}>
                      {this.justify(mmnt(this.props.hang.datetime).format('DD'))}
                    </div>
                    <div className="hang-year" style={hangYear}>
                      {this.justify(mmnt(this.props.hang.datetime).format('YYYY'))}
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <div className="hang-item-graphic" style={hangImage}>
        <div className="hang-actions">
          {count < limit &&
           !this.props.public &&
           this.props.user &&
           this.props.hang.uid !== this.props.user.uid &&
           this.state.inhang === false ?
          <button
            className="btn-hang-action black"
            onClick={() => this.joinHang(this.state.key, this.props.user.displayName, this.props.user.uid)}>
              <i className="fa fa-plus white"></i><br /> Join</button>
          : ''}
          {!this.state.edit &&
           this.props.user &&
           this.props.hang.uid === this.props.user.uid ||
           !this.state.edit &&
           this.state.inhang === true ?
          <Route render={({history}) => (
              <button
                className="btn-hang-action black"
                onClick={() => this.handleShareButton(shareUrl)}>
                <i className="fa fa-project-diagram white"></i><br />
                Invite
              </button>
            )}/>
          : ''}
          {this.props.public && !this.props.user &&
            <button
              className="btn-hang-action orange"
              onClick={() => { this.props.goHome(baseUrl) }}>
              <i className="fa fa-egg white"></i><br />
              Login &amp; <b>Join</b>
            </button>
          }
          {this.props.public && !this.props.user &&
            <button
              className="btn-hang-action pink"
              onClick={() => { this.props.toggleQuickJoin() }}>
              <i className="fa fa-ghost white"></i><br />
              Quick <b>Join</b>
            </button>
          }
          {!this.props.public && !this.props.detail &&
            <Route render={({history}) => (
              <button
                className="btn-hang-action"
                onClick={() => {history.push(hangLink)}}>
                <i className="fa fa-eye white"></i><br /> Details
              </button>
            )}/>
          }
          {!this.state.edit &&
           !this.props.public &&
           this.props.detail &&
           this.props.hang.uid === this.props.user.uid &&
           !this.props.hang.validhost &&
            <button
              className={"btn-hang-action green"}
              onClick={(e) =>
                this.props.setCheckLoc({chckloc: true})
              }>
              <i className="fa fa-dot-circle white"></i><br />
              Check-In
            </button>
          }
          {!this.state.edit &&
           !this.props.public &&
           this.props.detail &&
           this.props.hang.uid === this.props.user.uid &&
           this.props.hang.validhost &&
            <button
              className={"btn-hang-action green"}
              >
              <i className="fa fa-check-circle white"></i><br />
            </button>
          }
          {!this.state.edit &&
           !this.props.public &&
           this.props.detail &&
           this.props.hang.uid !== this.props.user.uid &&
           this.props.hang.validhost &&
           !this.state.valid &&
            <button
              className={"btn-hang-action green"}
              onClick={(e) =>
                this.props.setCheckLoc({chckloc: true})
              }>
              <i className="fa fa-dot-circle white"></i><br />
              Check-In
            </button>
          }
          {!this.state.edit &&
           !this.props.public &&
           this.props.detail &&
           this.props.hang.uid !== this.props.user.uid &&
           this.props.hang.validhost &&
           this.state.valid &&
            <button
              className={"btn-hang-action green"}
              >
              <i className="fa fa-check-circle white"></i><br />
            </button>
          }
          {incard &&
           this.props.detail &&
           this.props.hang.validhost &&
           this.props.hang.validcrew &&
          <Route render={({history}) => (
            <button
              className="btn-hang-action black"
              onClick={() => {history.push(`/crawl/${this.props.user.uid}`)}}>
              <i className="fa fa-coffee white"></i><br /> Card Punched!
            </button>
          )}/>
          }
          {!this.state.edit && !this.props.public && this.props.detail &&
           this.props.hang.uid === this.props.user.uid &&
           !this.props.hang.validhost &&
            <button
              className="btn-hang-action orange"
              onClick={() => this.toggleEdit()}>
              <i className={"fa fa-edit white"}></i><br />
              Edit
            </button>
          }
          {!this.state.edit && !this.props.public && this.props.detail &&
           this.props.hang.uid !== this.props.user.uid &&
           this.state.inhang &&
           !this.state.valid &&
            <a
              className="btn-hang-action"
              target="_blank"
              href={'https://www.google.com/maps/search/?api=1&query='+this.props.hang.lat+'%2C'+this.props.hang.lng+'&query_place_id='+this.props.hang.place}>
              <i className="fa fa-map-marker white"></i><br />
              Directions
            </a>
          }
          {/*!this.state.edit && !this.props.public && this.props.detail &&
           this.props.hang.uid !== this.props.user.uid &&
           this.state.inhang &&
             <Route render={({history}) => (
               <button
                 className="btn-hang-action green"
                 onClick={() => {history.push('/checkin/scan')}}>
                 <i className="fa fa-dot-circle white"></i><br />
                 <strong>Check-In</strong>
               </button>
             )}/>*/}
           {!this.state.edit && !this.props.public && this.props.detail &&
            this.props.hang.uid !== this.props.user.uid &&
            this.state.inhang &&
            !this.state.valid &&
             <button
              className={this.props.hang.validhost ?
              "btn-hang-action orange" :
              "btn-hang-action orange"}
              onClick={() => this.leaveHang(this.state.key, this.state.crewkey)}>
              <i className="fa fa-door-open white"></i><br />
              Leave
            </button>}
        </div>
        {!this.state.showdesc && this.props.hang.description &&
          <div
            className="btn-hang-info"
            onClick={(e) =>
              this.toggleInfo()
            }>
            <i className={this.state.placeimg ? "fa fa-info-circle" : "fa fa-info-circle blue"}></i>
          </div>
        }
        {!this.props.hang.image && !this.state.placeimg && !this.state.showdesc &&
        <div>
          <div id="map" ref={'map'} />
            <StaticGoogleMap
              size={this.props.mapsize}
              center={this.props.hang.lat+','+this.props.hang.lng}
              zoom="18"
              apiKey="AIzaSyCLpF3Kgl5ILBSREQ2-v_WNxBTuLi1FxXY"
              style={"element:geometry%7Ccolor:0xebe3cd&style=element:labels.text.fill%7Ccolor:0x523735&style=element:labels.text.stroke%7Ccolor:0xf5f1e6&style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0xc9b2a6&style=feature:administrative.land_parcel%7Celement:geometry.stroke%7Ccolor:0xdcd2be&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xae9e90&style=feature:landscape.natural%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:poi%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x93817c&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0xa5b076&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x447530&style=feature:road%7Celement:geometry%7Ccolor:0xf5f1e6&style=feature:road.arterial%7Celement:geometry%7Ccolor:0xfdfcf8&style=feature:road.highway%7Celement:geometry%7Ccolor:0xf8c967&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xe9bc62&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0xe98d58&style=feature:road.highway.controlled_access%7Celement:geometry.stroke%7Ccolor:0xdb8555&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x806b63&style=feature:transit.line%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:transit.line%7Celement:labels.text.fill%7Ccolor:0x8f7d77&style=feature:transit.line%7Celement:labels.text.stroke%7Ccolor:0xebe3cd&style=feature:transit.station%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:water%7Celement:geometry.fill%7Ccolor:0xb9d3c2&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x92998d&size=480x360"}
            >
              <Marker location={this.props.hang.lat+','+this.props.hang.lng} color="0xff0000" />
            </StaticGoogleMap>
        </div>
        }
        {this.state.edit &&
          <div className="hang-edit">
            <h4><span>Edit Hang</span></h4>
            <HangEdit
              hangkey={this.state.key}
              category={this.props.hang.category}
              datetime={this.props.hang.datetime}
              description={this.props.hang.description}
              image={this.props.hang.image}
              hash={this.props.hang.hash}
              toggleEdit={this.toggleEdit}
              visibility={this.props.hang.visibility}
            />
          </div>
        }
        {!this.state.edit && this.state.showdesc &&
          <div>
            <div className="hang-item-desc-wrapper">
              <div className="hang-item-desc">
                {this.props.hang.description ? this.props.hang.description : ''}
                {!this.props.hang.description && this.state.edit && 'Description appears here...'}
              </div>
            </div>
            <div
              className="hang-item-desc-close"
              onClick={(e) =>
                this.toggleInfo()
              }>
              <i className="fas fa-times"></i>
          </div>
          </div>
        }
        </div>
        </span>
        {!this.props.hang.placeHang &&
          <span className="hang-info">
          {this.props.hang.placeHang ?
          <span></span> :
          <span className="hang-member">
            {this.props.hang.userphoto ?
            <img src={this.props.hang.userphoto} alt={this.props.hang.user} className="hang-host" /> :
            <img src={Gravatar.url(this.props.hang.user.email, {s: '100', r: 'x', d: 'retro'}, true)} alt={"Profile Picture for:"+this.props.hang.user.email} />}
            <span>
            <b>Host</b><br />
            <span>{this.props.hang.user}</span>
            </span>
            {/* this.props.hang.uid !== this.props.user.uid && this.state.mutualFriends !== 0  ?
            <span className="hang-number">{this.state.mutualFriends}</span>
            : '' */}
          </span>}
          {this.state.openCalendar ? <span className="hang-ui"><AddToCalendar event={event} buttonLabel="Add to calendar" buttonTemplate={icon} /><i className={'fa fa-times'} onClick={() => this.toggleCalendar()}></i></span> :
            <span className="hang-ui">
              {!this.props.public && this.state.inhang ?
              <button className="fa fa-link" onClick={() => this.handleShareButton(shareUrl)}></button>
              : null }
              {!this.props.public && this.state.inhang ?
              <FacebookShareButton url={shareUrl} quote={'Join Me on Hangerang: '+this.props.hang.title}>
                <FacebookIcon size={24} round />
              </FacebookShareButton>
              : null }
              {!this.props.public && this.state.inhang ?
              <TwitterShareButton url={shareUrl} quote={'Join Me on Hangerang: '+this.props.hang.title}>
                <TwitterIcon size={24} round />
              </TwitterShareButton>
              : null }
              {!this.props.public && this.state.inhang ?
              <EmailShareButton url={shareUrl} quote={'Join Me on Hangerang: '+this.props.hang.title}>
                <EmailIcon size={24} round />
              </EmailShareButton>
              : null }
              {!this.props.public && this.state.inhang ?
              <button className="fa fa-calendar" onClick={() => this.toggleCalendar()}></button>
              : null }
              {!this.props.public && this.props.hang.uid === this.props.user.uid ?
              <i className="fa fa-trash" onClick={() => this.removeHang(this.state.key)}><span>Remove Hang</span></i>
              : ''}
              {count >= limit && <span className={'capsule small'}>Hang Limit Reached</span>}
            </span>
          }
        </span>}
        {this.props.public ? <HangCrew localHangChange={this.localHangChange} hang={this.state.key} crew={this.props.hang.crew} public={this.props.public} /> :
         <HangCrew
          localHangChange={this.localHangChange}
          hang={this.state.key}
          uid={this.props.user.uid}
          crew={this.props.hang.crew}
          validhost={this.props.hang.validhost}
          timestamp={this.props.hang.timestamp}
        />}
        { this.props.detail && !this.props.public && this.state.inhang ?
        <HangChat localHangChange={this.localHangChange} hang={this.state.key} username={this.props.username} userphoto={this.props.userphoto} />
        : ''}
      </span>;

    let HangItem;
    if(this.props.detail === true){
      HangItem = <div><canvas ref="canvas" width="600" height="400" style={{display: 'none'}}></canvas><img alt="Hang" id="img" style={{display: 'none'}} /><div className={'hang-item '+this.state.visibility} key={this.state.key}>{Hang}</div></div>;
    }else{
      HangItem = <div className={'hang-item '+this.state.visibility} key={this.state.key}>{Hang}</div>;
    }

    if(!this.props.public && this.props.hang.uid === this.props.user.uid){
      return HangItem;
    }else if( this.state.visibility === 'show'){
      return HangItem;
    }else if(this.props.hang.visibility === 'public'){
      return HangItem;
    }else if(this.props.detail){
      return HangItem;
    }else if(this.props.invited){
      return HangItem;
    }else{
      return null;
    }
  }
}

export default HangItem;
