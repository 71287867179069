import React from "react";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import VisibilitySensor from 'react-visibility-sensor';
import firebase from './firebase.js';
import geolib from 'geolib';
import fsm from 'fuzzy-string-matching';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import _, { shuffle } from 'lodash';

class GemCard extends React.Component {
  constructor() {
    super();
    this.state = {
      place: {},
      placeimg: false,
      distance: 0,
      open: null,
      location: {}
    }
    this.getPlace = this.getPlace.bind(this);
    //this.placeCallback = this.placeCallback.bind(this);
    this.startHang = this.startHang.bind(this);
  }

  handleURL = (url) => {
    console.log(url);
    var win = window.open(url, '_blank');
    win.focus();
  }

  startHang = () => {
    const { location, place } = this.state;
    setTimeout(() => {
      this.props.toggleForm();
      this.props.setLocation(location);
      this.props.setName(place.name);
      this.props.setTitle(this.props.hangTitle+' '+place.name);
    },500);
  }

  getPlace = (id) => {
    const { lat, lng } = this.props;
    let maploc = new window.google.maps.LatLng(lat,lng);
    let mapdom = ReactDOM.findDOMNode(this.refs.map);
    let map = new window.google.maps.Map(mapdom, {
        center: maploc,
        zoom: 20
      });
    let service = new window.google.maps.places.PlacesService(map);
    service.getDetails({
        placeId: id
    }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        if(result.photos && result.photos.length > 0){
          var placePhotoUrl = result.photos[0].getUrl({maxWidth:630});
          this.setState({placeimg: placePhotoUrl});
          if(result.opening_hours){
            this.setState({
              open: result.opening_hours.open_now,
              website: result.website,
              url: result.url,
              address: result.vicinity
            });
          }
          this.setState({location: {
            formatted_address: result.vicinity,
            place_id: result.place_id,
            geometry: result.geometry,
          }});
        }
      }
    });
  }

  openPopupbox = () => {
    const { place } = this.state;
    this.getPlace(place.pid);
    ReactGA.event({category: 'User', action: 'Gem Popup Opened'});
    let height = "300px";
    if( window.innerWidth < 414 ){
      height = "250px";
    }
    if( window.innerWidth < 375 ){
      height = "200px";
    }
    setTimeout( () => {
      let shuffled = _.shuffle(Object.entries(place.comments));
      let comment = shuffled.slice(0,1);
      comment = comment[0][1]['comment'];
      var Image = {
        backgroundImage: "url('"+this.state.placeimg+"')",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        overflow: "hidden",
        cursor: "pointer",
        height: height,
      }
      const content = (
        <div className={'card-popup'}>
          <i className={this.state.placeimg ? 'fa fa-times white' : 'fa fa-times'}
          onClick={()=>{PopupboxManager.close()}}>
          </i>
          <div style={Image} className={'card-popup-header'}></div>
          <div className={'card-popup-content'}>
            <h5>{place.name}</h5>
            <div><i onClick={() => {this.handleURL(this.state.url)}} className={'fa fa-map-marker small'}></i></div>
            <div className="x-small">
              <strong>{this.state.address}</strong>
            </div>
            <div className="small">{this.state.distance}mi</div>
            {this.state.open !== null && <div className={this.state.open ? "small open-status green" : "small open-status red white"}>
              {this.state.open ? 'Open Now' : 'Not Open Now'}
            </div>}
            <div className={'card-popup-comments'}>
              {comment && <div className='small'>"{comment}"</div>}
            </div>
          </div>
          <div
            className={'card-popup-bottom'}
            onClick={()=>{this.startHang(); PopupboxManager.close();}}
          >
            <div className={'card-popup-makehang'}>
              <strong>Hang</strong> Here
            </div>
          </div>
        </div>
      );
      PopupboxManager.open({ content });
    },1000);
  }

  componentDidMount = () => {
    const { item, lat, lng } = this.props;
    const placeRef = firebase.database().ref(`/places/${item}`);
    placeRef.once('value', (snapshot) => {
      let placekey = Object.keys(snapshot.val())[0];
      let place = snapshot.val();
      this.setState({ place: place });
    });

    const placeGLRef = firebase.database().ref(`/places-gl/${item}`);
    placeGLRef.once('value', (snapshot) => {
      let pgl = snapshot.val();
      let distance = geolib.getDistance(
        {latitude: lat, longitude: lng},
        {latitude: pgl['l'][0], longitude: pgl['l'][1]}
      );
      distance = (distance/1000) * 0.621371;
      distance = distance.toFixed(1);
      this.setState({distance});
    });

  }

  render() {
    const { place } = this.state;

    var Image = {
      //backgroundImage: "url('"+item.imgMedium+"')",
      backgroundColor: "#000",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      overflow: "hidden",
      height: "144px",
      borderRadius: "0.5rem",
      cursor: "pointer"
    }
    //
    // var Price = [];
    // var i;
    // for(i = 0; i < place.price_range; i++){
    //     Price.push(<img key={'dollar-'+i} src={Dollar} />);
    // }
    //
    // let rating = 0;
    // //rating = place.user_rating.aggregate_rating;
    // //rating = parseInt(rating);

    return (
      <div>
        {place && <div>
          <div id="map" ref={'map'} />
          <div onClick={this.openPopupbox} className="content">
            <div><i className="fa fa-gem white"></i></div>
            <h4>{place.name}</h4>
            <div className="x-small">{this.state.distance}mi</div>
          </div>
          {this.state.placeimg && <span></span>}
          <div style={Image}></div>
        </div>}
      </div>
    );
  }
}

export default GemCard;
