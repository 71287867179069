import React from "react";
import ReactGA from 'react-ga';
import firebase, { base } from './firebase.js';
import {Redirect, Route} from 'react-router-dom';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';

class MarketAdmin extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        name: '',
        place: '',
        points: '',
        provider: '',
        image: '',
        description: '',
        availability: ''
      }
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    saveItem = () => {
      const {
        name,
        place,
        points,
        provider,
        image,
        description,
        availability
      } = this.state;

      const itemsRef = firebase.database().ref(`items`);
      let item = {
        name: name,
        place: place,
        points: points,
        provider: provider,
        image: image,
        description: description,
        availability: availability
      }
      itemsRef.push(item);
      this.setState({
        name: '',
        place: '',
        points: '',
        provider: '',
        image: '',
        description: '',
        availability: ''
      });
    }

    render() {
      if (this.props.userkey !== '-LJpKswYDeGiDUy46FuM') {
         return <Redirect to='/' />
      }

      const {
        name,
        place,
        points,
        provider,
        image,
        description,
        availability
      } = this.state;

        return (
            <div className="page-wrapper">
              <h3 className="center">Market Admin - Add Item</h3>
              <MuiThemeProvider>
                <TextField
                  type="text"
                  name="name"
                  placeholder="Item Name"
                  onChange={this.handleChange}
                  value={this.props.name} />
                <TextField
                  type="text"
                  name="description"
                  placeholder="Description"
                  onChange={this.handleChange}
                  value={this.props.description} />
                <TextField
                  type="text"
                  name="place"
                  placeholder="Place"
                  onChange={this.handleChange}
                  value={this.props.place} />
                <TextField
                  type="text"
                  name="points"
                  placeholder="Points"
                  onChange={this.handleChange}
                  value={this.props.points} />
                <TextField
                  type="text"
                  name="provider"
                  placeholder="Provider"
                  onChange={this.handleChange}
                  value={this.props.provider} />
                <TextField
                  type="text"
                  name="image"
                  placeholder="Image"
                  onChange={this.handleChange}
                  value={this.props.image} />
                <TextField
                  type="text"
                  name="availability"
                  placeholder="Number Available"
                  onChange={this.handleChange}
                  value={this.props.availability} />
                <div className="center">
                  <button className='btn' onClick={() => {this.saveItem()}}>
                    Save
                  </button>
                </div>
              </MuiThemeProvider>
            </div>
        );
    }
}

export default MarketAdmin;
