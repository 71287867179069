import React from "react";
import ReactGA from 'react-ga';
import firebase, { base } from './firebase.js';
import {Redirect, Route} from 'react-router-dom';
import axios from 'axios';

const sgMail = require('@sendgrid/mail');
sgMail.setApiKey("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");

class InviteAdmin extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        count: 0,
        members: [],
        log: '',
      }
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    componentDidMount(){
      base.fetch(`/members/`, {
        context: this,
        asArray: true,
        then(members){
          this.setState({members: members});
          members.map((member, key) => {
            if(member.invite){
              let count = this.state.count + 1;
              this.setState({count: count });
            }
          });
        }
      });
    }

    sendReminderEmails(){
      const { members } = this.state;
      if(members){
        Object.entries(members).map((member, key) => {
          if(member[1].invite){
            let axiosConfig = {
              headers: { 'X-Requested-With':'XMLHttpRequest' }
            };
            axios.get(
              'https://cors-anywhere.herokuapp.com/https://us-central1-hang-serve.cloudfunctions.net/inviteEmail/'+member[1].key)
              .then((res) => {
                console.log("RESPONSE RECEIVED: ", res);
                return;
              })
              .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            });
            this.setState({log: this.state.log+'Email Sent to: '+member[1].name+'<br />'});
            setTimeout(() => {
              const memberRef = firebase.database().ref(`members/${member[1].key}`);
              let date = new Date();
              let timestamp = date.getTime();
              memberRef.update({reminded: timestamp});
            },5000);
          }
        });
      }
    }

    render() {
      // if (this.props.userkey !== '-LJpKswYDeGiDUy46FuM') {
      //    return <Redirect to='/' />
      // }

      const {
        count,
        members,
      } = this.state;

        return (
            <div className="page-wrapper">
              <h3 className="center">Invite Admin</h3>
              <p><strong>{count}</strong> of <strong>{members.length}
              </strong> Users Have Pending Invites</p>
              {!this.state.log && <div>
                <button
                  className="btn red"
                  onClick={() => this.sendReminderEmails()}>
                  Send Reminder Email
                </button>
              </div>}
              {this.state.log && <div>
              <br />
              <hr />
              <div dangerouslySetInnerHTML={{__html: this.state.log}} />
              </div>}
            </div>
        );
    }
}

export default InviteAdmin;
