import React from "react";
import firebase, {base} from './firebase.js';
import { Route } from 'react-router-dom';
import { GeoFire } from 'geofire';
import ReactGA from 'react-ga';
import Hashids from 'hashids';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import AnimatedNumber from 'react-animated-number';
import AddName from './addname.js';
import AddPhone from './addphone.js';
import GoogleSuggest from './places.js';
import ip from 'ip';
import ipLocation from "iplocation";
import Location from '../assets/location.png';
import SuperHero from '../assets/superhero.png';
import Trophy from '../assets/award.png';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

var hashids = new Hashids('', 5);

class Hero extends React.Component {
    constructor() {
      super();
      this.state = {
        clearField: false,
        location: '',
        places: {},
        step: 1,
        validcode: -1,
      }
      this.setHasShared = this.setHasShared.bind(this);
      this.setGetLocation = this.setGetLocation.bind(this);
      this.setStep = this.setStep.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleSelect = this.handleSelect.bind(this);
      this.handleShareButton = this.handleShareButton.bind(this);
    }

    handleShareButton(url) {
      this.props.openPopupBox(url);
      ReactGA.event({category: 'User', action: 'Share Generated'+url});
      this.setHasShared();
    }

    handleChange(e) {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    setHasShared() {
      this.setState({ hasShared: true });
    }

    setGetLocation() {
      this.setState({ getLocation: true });
    }

    setStep(step){
      this.setState({step: step});
    }

    setClearField = (bool) => {
      this.setState({clearField: bool});
    }

    stepSkipped(step){
      ReactGA.event({category: 'User', action: 'Onboard Step '+step+' Skipped'});
    }

    submitInviteCode(){
      const { userkey } = this.props;
      if(this.state.special === 'HERO505'){
        this.setStep(2);
        const memberRef = firebase.database().ref(`/members/${userkey}`);
        memberRef.update({
          hero: true,
        });
        base.listenTo(`/members/${userkey}/places`, {
          context: this,
          then(places){
            console.log(places);
            this.setState(prevState => ({
              places: places,
            }));
          }
        });
      }else{
        this.setState({validcode: false});
      }
    }

    handleSelect(str) {
      let selected = this.state.selected;
      let index = selected.indexOf(str);
      if(index === -1){
        this.setState({ selected: [...this.state.selected, str] })
      }else{
        selected.splice(index, 1);
        this.setState({selected: selected});
      }
    }

    setHeroPlace = () => {
      console.log(this.props.location.place_id);
      const { clearLocation, clearName, location, name, setLocation, setName, userkey } = this.props;
      const placeRef = firebase.database().ref('places');
      placeRef.orderByChild("pid").equalTo(location.place_id).once('value', (snapshot) => {
          if (snapshot.exists()) {
            let place = snapshot.val();
            let placekey = Object.keys(place)[0];
            const heroPlacesRef = firebase.database().ref(`/members/${userkey}/places/`);
            if(place[placekey].owner){
              console.log('Hero exists');
              this.setState({error: "Place already claimed by a Hero."});
            }else{
              place = {
                  place: placekey,
                  pid: location.place_id,
                  name: name
              }
              heroPlacesRef.push(place);
              const placeInst = firebase.database().ref(`places/${placekey}`);
              placeInst.update({owner: userkey});
              console.log('Place exists. Add to Hero');
              this.setState({clearField: true});
            }

            return;
          }else{
            const newPlace = {
              pid: location.place_id,
              name: name,
            }
            placeRef.push(newPlace).then((snap) => {
              const heroPlacesRef = firebase.database().ref(`/members/${userkey}/places/`);
              let place = {
                  place: snap.key,
                  pid: location.place_id,
                  name: name
              }
              heroPlacesRef.push(place);

              const placeInst = firebase.database().ref(`places/${snap.key}`);
              placeInst.update({owner: userkey});

              console.log('Place not in DB. Create and add to Hero');
            });
            console.log('Place created in database. Add to Hero');
            this.setState({clearField: true});
            return;
          }
      }).catch(function(error) {
        console.log(error);
      });
    }

    render() {
        const {
          step
        } = this.state;
        const { location } = this.props;
        var baseUrl = window.location.protocol + "//" + window.location.host;
        var shareUrl = baseUrl+'/invite/user/'+this.state.hash;

        let Places = Object.entries(this.state.places).map((place,i) => {
            place = place[1];
            return (<tr>
              <td className="left" key={`place-${i}-name`}>{place.name}</td>
              <td className="right" key={`place-${i}-pid`}>{place.place}</td>
            </tr>);
        });

        return (
            <div className="page-wrapper onboarding">
              <ul className="steps">
                <li className={step === 1 ? 'active' : ''}>1</li>
                <li className={step === 2 ? 'active' : ''}>2</li>
                <li className={step === 3 ? 'active' : ''}>3</li>
              </ul>
              {step === 1 && <div>
                <h3>
                You've been officially invited to become a Hang Hero!
                </h3>
                <p><img src={Trophy} alt="Award Trophy" /></p>
                <hr />
                <p>As a Hero you'll have special privileges that
                typical Hangerang users don't have access to.</p>
                <hr />
                <b>Privileges Such As:</b>
                <ol className="left">
                  <li>Ability to post Hangs for claimed places or businesses</li>
                  <li>Add photos to your Hangs</li>
                  <li>Change Hang location, date, and time</li>
                  <li>Custom QR Codes immediately printable for Hangs and Places</li>
                  <li>See data analysis on Hang performance</li>
                </ol>
                {this.state.validcode === false &&
                  <div className="red">Sorry, that code is invalid. Please try again.</div>
                }
                <MuiThemeProvider>
                <TextField
                  name='special'
                  placeholder="Enter Special Invite Code"
                  onChange={this.handleChange}
                  value={this.state.special}
                />
                <button
                 className="btn blue"
                 onClick={() => {this.submitInviteCode()}}
                >Submit</button>
                </MuiThemeProvider>
              </div>}
              {step === 2 && <div className="step-with-btn">
                <h3>
                Your Places
                </h3>
                <p>Is there a specific place or business that you will represent?</p>
                <hr />
                {this.state.places
                  && Object.entries(this.state.places).length > 0 &&
                  <table className="small groups-table">
                  <thead>
                    <tr>
                      <td className="left"><b>Name</b></td>
                      <td className="right"><b>Place ID</b></td>
                    </tr>
                  </thead>
                  <tbody>
                  {Places}
                  </tbody>
                  </table>}
                <MuiThemeProvider>
                  {this.state.error && <div className="red">
                    {this.state.error}&nbsp;<i className="fa fa-times"
                      onClick={() => {
                        this.setState({error: ''});
                        this.setClearField(true);
                      }
                    }></i>
                  </div>}
                  <div className="relative">
                    <GoogleSuggest
                     name="place"
                     onLocChange={this.props.setLocation}
                     onNameChange={this.props.setName}
                     clearField={this.state.clearField}
                     setClearField={this.setClearField}
                     placeholder="Please Enter The Place"
                    />
                    <button
                     className="add-place-btn"
                     onClick={() => {this.setHeroPlace()}}
                    >Add</button>
                  </div>
                </MuiThemeProvider>
                <button
                 className="step-btn"
                 onClick={() => {
                   this.setStep(3);
                   this.props.setUserState(this.props.user);
                 }}
                >
                {this.state.places
                  && Object.entries(this.state.places).length > 0
                  ? 'Next' : "I don't represent any places"}
                </button>
              </div>}
              {step === 3 && <div className="step-with-btn">
                <h3>
                Rise Hero!
                </h3>
                <p>
                  Wasn't that easy?<br />
                  Time for some Hangerang Heroics!
                </p>
                <p><img src={SuperHero} alt="Superhero" /></p>
                <Route render={({history}) => (
                  <button
                   className="step-btn"
                   onClick={() => {history.push('/') }}
                  >Go Home</button>
                )}/>
              </div>}
            </div>
        );
    }
}

export default Hero;
