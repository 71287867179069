import React, { Component } from 'react';
import firebase, {base} from './firebase.js';
import NativeSelect from '@material-ui/core/NativeSelect';

class PlaceSelect extends Component {
  constructor(props){
    super(props)
    this.state = {
      place: '',
    }
  }

  handleSelect = event => {
    this.setState({ [event.target.name]: event.target.value });
    this.props.handlePlaceChange(
      event.target.value, event.target.selectedOptions[0].text
    );
  }

  render(){
    let Options = this.props.options.map((option,i) => {
      return (
          <option value={option.value} name={option.label} key={'option-'+option.value}>{option.label}</option>
      )
    });

    return(
      <NativeSelect
        value={this.props.placeHang}
        onChange={this.handleSelect}
        name="place"
        value={this.state.place}
        inputProps={{
          name: 'place',
          id: 'place-sel',
        }}
      >
        <option value=''>Is this Hang for a Place?</option>
        {Options}
      </NativeSelect>
    )
  }
}

export default PlaceSelect;
