import React, { Component } from 'react';
import firebase, { base } from './firebase.js';
import Gravatar from 'gravatar';
import axios from 'axios';

class UserPhoto extends Component {
  constructor(props){
    super(props)
    this.state = {
      userphoto: Gravatar.url('', {s: '100', r: 'x', d: 'retro'}, true),
      name: '',
    }
  }

  componentWillMount = () => {
    if(this.props.uid){
      const memberRef = firebase.database().ref(`/members`);
      memberRef.orderByChild("uid").equalTo(this.props.uid).once('value',
        (snapshot) => {
          if (snapshot.exists()) {
            let member = snapshot.val();
            var key = Object.keys(snapshot.val())[0];
            member = member[key];
            if(member.userphoto){
              axios.get(member.userphoto).then((res) => {
                if (res.status === 200) {
                    this.setState({
                      userphoto: member.userphoto
                    });
                }
              }).catch(err => {
                console.log('Error:', err);
              });
          }else{
            this.setState({
              userphoto: Gravatar.url(member.email, {s: '100', r: 'x', d: 'retro'}, true)
            });
          }
        }
      });
    }
    if(!this.props.uid && this.props.email){
      this.setState({
        userphoto: Gravatar.url(this.props.email, {s: '100', r: 'x', d: 'retro'}, true)
      });
    }
  }

  render(){
    const {userphoto} = this.state;
    return(
      <span>{userphoto && <img
        className='user-profile-img'
        src={userphoto}
        alt={"Profile Picture for: "+this.props.user}
      />}</span>
    )
  }
}

export default UserPhoto;
