import React from "react";
import ReactGA from 'react-ga';
import axios from 'axios';
import firebase, { base } from './firebase.js';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import Gravatar from 'gravatar';

class MemberRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      member: false,
      invited: false,
      userphoto: Gravatar.url(this.props.member.email, {s: '100', r: 'x', d: 'retro'}, true),
    }
  }

  componentDidMount = () => {
    if(this.props.member){
      const memberRef = firebase.database().ref(`/members/`);
      memberRef.orderByChild("uid").equalTo(this.props.member.uid).once('value', (snapshot) => {
        if (snapshot.exists()) {
          let key = Object.keys(snapshot.val())[0];
          let member = snapshot.val();
          member = member[key];
          this.setState({member: member});
            if(member.userphoto){
              axios.get(member.userphoto).then((res) => {
                if (res.status === 200) {
                    this.setState({
                      userphoto: member.userphoto
                    })
                }
              }).catch(err => {
                console.log('Error:', err);
              });
          }else{
            this.setState({
              userphoto: Gravatar.url(member.email, {s: '100', r: 'x', d: 'retro'}, true)
            })
          }
        }
      });
    }
  }

  openPopupbox = () => {
    const { member, userphoto } = this.state;
    ReactGA.event({category: 'User', action: 'Crew: Crew Member Profile Popup'});
    const content = (
      <div className="user-profile-popup">
        <i className={'fa fa-times'}
        onClick={()=>{PopupboxManager.close()}}>
        </i>
        <div className='user-profile'>
          <img className='user-profile-img'
               src={userphoto}
               alt={"Profile Picture for:"+member.name} />
        </div>
        <strong>{member.name}</strong>
        <div className="capsules">
          {member.interests && member.interests.map((i) => {
            return(<span key={`interest-${i}`} className={'small capsule capitalize '+i}>{i.replace('-',' & ')}</span>)
          })}
        </div>
        <hr />
        {member.gem && <div className="user-gem small">
          <div><i className='fa fa-gem'></i></div>
          <div><strong>{member.gem.name}</strong></div>
          <div>"{member.gem.comment}"</div>
        </div>}
      </div>
    )
    PopupboxManager.open({ content });
  }

  render() {
    const { member, id, removeCrewMember } = this.props;
    if(member){
      return (
        <div className="crew-member-row" key={`crew-member-${id}`}>
          <img src={this.state.userphoto} alt={member.user} className="crew-member-image" />
          <div
            onClick={this.openPopupbox}
            className="small crew-member-name">
            {member.user}
          </div>
          <div
            onClick={() => { removeCrewMember(id) }}
            className="fas fa-times crew-member-remove"></div>
        </div>
      );
    }else{
      return null;
    }
  }
}

export default MemberRow;
