import React from "react";
import ReactGA from 'react-ga';
import {Redirect, Route} from 'react-router-dom';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import axios from 'axios';
import QRCode from 'qrcode.react';
import firebase, {base} from './firebase.js';
import Gravatar from 'gravatar';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import validator from 'validator';
import AddName from './addname.js';
import HangItem from './hangitem.js';
import GeoCheck from './geocheck.js';

class HangDetail extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      hang: [],
      image: [],
      key: '',
      token: '',
      placekey: '',
      chckloc: false,
      geoReady: '',
      geoCheck: false,
      showQuickJoin: false,
      timeout: false,
      username: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.onHangChange = this.onHangChange.bind(this);
    this.getPlaceKey = this.getPlaceKey.bind(this);
    this.toggleQuickJoin = this.toggleQuickJoin.bind(this);
  }

  setAddress = (address) => this.setState({ address })
  setGeoCheck = (geoCheck) => this.setState({ geoCheck });


  setCheckLoc = () => {
    this.setState({ chckloc: true });
    // if(this.state.hang.validhost && this.state.key &&
    //   this.props.userphoto === this.state.hang.userphoto){
    //     this.openPopupbox();
    //   }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  goHome = (url) => {
    console.log(url);
    window.location.replace(url);
  }

  openPopupbox = () => {
      const content = (
        <div className="center">
          <div className="hang-qr">
            <QRCode
              value={"/checkin/"+this.state.placekey}
              size='256'
              fgColor='#000000'
              bgColor='#ffffff'
              level='L'
              renderAs='svg'
            />
            <p>Show this to your <b>Crew</b> to allow them to <b>Check-In</b><br />
            <span onClick={()=>{PopupboxManager.close()}}>
              <i className='fa fa-times'></i> Close
            </span>
            </p>
          </div>
        </div>
    );
    PopupboxManager.open({ content });
  }

  quickJoinSubmit = () => {
    const crewRef = firebase.database().ref(`/hangs/${this.state.key}/crew/`);
    crewRef.orderByChild("email").equalTo(this.state.email).once('value', (snapshot) => {
      if (snapshot.exists()) {
        console.log('already added to hang');
      }else{
        let member = {
          email: this.state.email,
          user: this.state.username,
          userphoto: Gravatar.url(this.state.email, {s: '100', r: 'x', d: 'retro'}, true)
        }
        //console.log(member);
        crewRef.push(member);
        ReactGA.event({category: 'User', action: 'Hang Joined (Quick Join)'});
        this.toggleQuickJoin();
      }
    });
  }

  toggleQuickJoin() {
    this.setState({ showQuickJoin: !this.state.showQuickJoin });
  }

  onHangChange(hangid) {
    const hangRef = firebase.database().ref(`/hangs/${hangid}`);
    hangRef.once('value', (snapshot) => {
       if(snapshot.exists()){
         let hang = snapshot.val();
         this.setState({ hang });
       }
     });
  }

  getPlaceKey(place) {
    const placesRef = firebase.database().ref(`/places/`);
    placesRef
    .orderByChild('pid')
    .equalTo(place)
    .limitToFirst(1)
    .once('value', (snapshot) => {
       if(snapshot.exists()){
         let key = Object.keys(snapshot.val())[0];
         this.setState({ placekey: key });
       }
     });
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({timeout: true});
    },2000);
    ReactGA.event({category: 'User', action: 'Hang Viewed'});
    if(this.props.hash){
      const hangRef = firebase.database().ref(`/hangs/`);
      //Add Joiner to Host Crew
      hangRef.orderByChild("hash").equalTo(this.props.hash).once('value', (snapshot) => {
        if (snapshot.exists()) {
          let key = Object.keys(snapshot.val())[0];
          let hang = snapshot.val();
          hang = hang[key];
          this.setState({key: key});
          base.syncState(`hangs/${key}`, {
            context: this,
            state: 'hang',
            keepKeys: true
          });
          this.getPlaceKey(hang.place);
        }
      });
      // axios.get(`https://firebasestorage.googleapis.com/v0/b/fun-food-friends-cf17d.appspot.com/o/images%2F${this.props.hash}.png`)
      //   .then(res => {
      //     const token = res.downloadTokens;
      //     this.setState({ token });
      //   });
    }
  }

  render(){
      if (this.state.timeout && !this.state.hang.title) {
         console.log('Redirect?');
         return <Redirect to='/' />
      }

      let firstName = '';
      if(this.state.hang.user){
        let split = this.state.hang.user.split(' ');
        firstName = split[0];
      }

      return (
        <div className="wrapper">
        {this.state.showQuickJoin && <div className={'modal'}>
          <div className={'modal-card card-popup'}>
            <i className={'fa fa-times'}
              onClick={()=>{this.toggleQuickJoin()}}>
            </i>
            <div className={'card-popup-content no-hor-padding'}>
              <h4>Quick RSVP</h4>
              <div className="small">
                No need to create a <b>Hangerang</b> account<br />
                to let <b>{firstName}</b> know you can make it.
              </div>
              <hr />
              <MuiThemeProvider>
                <div>
                  <b className="small">Please enter your Name and Email</b>
                  <div>
                    <TextField
                      type="text"
                      name="username"
                      placeholder="First Name + Last Name"
                      value={this.state.username}
                      onChange={this.handleChange}
                    />
                    {this.state.username &&
                     validator.matches(this.state.username,/^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/i) &&
                    <i class="fas fa-check green"></i>}
                  </div>
                  <div>
                    <TextField
                      name='email'
                      placeholder="Email"
                      onChange={this.handleChange}
                      value={this.state.email}
                    />
                    {this.state.email &&
                     validator.isEmail(this.state.email) &&
                    <i class="fas fa-check green"></i>}
                  </div>
                </div>
              </MuiThemeProvider>
              </div>
              {this.state.username &&
               validator.matches(this.state.username,/^([a-zA-Z]{2,}\s[a-zA-z]{1,}'?-?[a-zA-Z]{2,}\s?([a-zA-Z]{1,})?)/i) &&
               this.state.email &&
                validator.isEmail(this.state.email) &&
               <div
                className={'card-popup-bottom'}
                onClick={()=>{this.quickJoinSubmit(); PopupboxManager.close();}}
              >
                <div className={'card-popup-makehang'}>
                  Submit + RSVP
                </div>
              </div>}
          </div>
        </div>}
        {Object.keys(this.state.hang).length > 0 ?
          <section className="hang-detail">
            {this.state.chckloc ?
              <GeoCheck
              user={this.props.user}
              hang={this.state.hang}
              getGeoCheck={this.getGeoCheck}
              setCheckLoc={this.setCheckLoc}
              hangKey={this.state.key}
              userkey={this.props.userkey}
               />
            : ''}
            <HangItem
              onHangChange={this.onHangChange}
              hang={this.state.hang}
              user={this.props.user}
              username={this.props.username}
              userphoto={this.props.userphoto}
              token={this.props.token}
              id={this.state.key}
              mapsize={'600x500'}
              detail={true}
              openPopupBox={this.props.openPopupBox}
              setCheckLoc={this.setCheckLoc}
              quickJoin={this.quickJoin}
              public={this.props.public}
              goHome={this.goHome}
              toggleQuickJoin={this.toggleQuickJoin}
            />
          </section>
        : <div className="center page-spinner"><i className="fa fa-circle-notch fa-spin"></i></div> }
        </div>
      );
    }

}

export default HangDetail;
