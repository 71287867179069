import React from "react";
import Async from 'react-promise';
import firebase, { base } from './firebase.js';
import { GeoFire } from 'geofire';
import geolib from 'geolib';
import { geolocated } from 'react-geolocated';
import Hashids from 'hashids';
import QRCode from 'qrcode.react';
import { PopupboxManager, PopupboxContainer } from 'react-popupbox';
import Validator from './validator.js';

import cutbow from '../assets/crawl/cutbow_w.png';
import littlebear from '../assets/crawl/littlebear_w.png';
import prismatic from '../assets/crawl/prismatic_w.png';
import remedy from '../assets/crawl/remedy_w.png';
import rust from '../assets/crawl/rustisgold_w.png';
import zendo from '../assets/crawl/zendo_w.png';

/*
Humble Downtown: -LKXsfeTzOmVmHsEoWxD
Humble Lomas: -LKyK8Vq4Z6mqjbJo3-v
Little Bear: -LKyKDjkGtUb0PYMy4oU
Villa Myriam: -LKyKNoL1Yt8tN6y-5KO
The Brew: -LKyKYI0sPo_XYKMTJMD
Zendo: -LKyKgci1oueqrdngpKQ
Prismatic: -LKyKmgEGbiR2WPyYD4H
Rust Is Gold: -LiE1jvLzienlBxLsiDI
*/
const disloyalPlaces = [
'-LiLz6MJUJSBhI4IdSel',
'-LKyKDjkGtUb0PYMy4oU',
'-LKyKmgEGbiR2WPyYD4H',
'-LkNMw6Fe_oOiKk5wNug',
'-LiE1jvLzienlBxLsiDI',
'-LKyKgci1oueqrdngpKQ',
'-Lg-ryjHaSRZfBc-ixfD',
]

var hashids = new Hashids('', 5);

class Redeem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      checking: false,
      creditkey: '',
      credit: false,
      distance: '',
      place: false,
      redeem: false,
      status: false
    }
  }

  getLocale = (lat,lng,uid) => {

      let usersRef = firebase.database().ref('members');
      let usersGeoRef = firebase.database().ref('members-gl');
      let geoUser = new GeoFire(usersGeoRef);

      usersRef.orderByChild("uid").equalTo(uid).once('value', (snapshot) => {
        if (snapshot.exists()) {
          let key = Object.keys(snapshot.val())[0];
          let member = snapshot.val();
          member = member[key];
          geoUser.set(key, [lat, lng]).then(() => {
              this.setState({checking: true});
              disloyalPlaces.map((pid) => {
                const placeGLRef = firebase.database().ref(`/places-gl/${pid}`);
                placeGLRef.once('value', (snapshot) => {
                  let place = snapshot.val();
                  let place_lat = place['l'][0];
                  let place_lng = place['l'][1];
                  let distance = geolib.getDistance(
                    {latitude: lat, longitude: lng},
                    {latitude: place_lat, longitude: place_lng}
                  );
                  if(distance < 100){
                    const placeRef  = firebase.database().ref(`/places/${pid}`);
                    placeRef.once('value', (snapshot) => {
                      let place = snapshot.val();
                      console.log(`You are near ${place.name}`);
                      this.setState({place: place, checking: false});
                    });
                  }
                });
              });
              setTimeout(() => {
                if(!this.state.place){
                  this.setState({checked: true, redeem: false, checking: false});
                }
              }, 2000);
            });
        }
      });
  }

  genCert() {
    const creditsRef = firebase.database().ref('credits');
    const membersRef = firebase.database().ref('members');
    const placesRef = firebase.database().ref('places');
    var key = Date.now();
    key = key.toString().split("").map(num => parseInt(num, 0));
    key = key.splice(8, 5);
    key = key.sort(function(a, b){ return 0.5 - Math.random() });
    const itemHash = hashids.encode(key);

    let now = new Date();

    const credit = {
      hash: itemHash,
      pid: this.state.place.pid,
      uid: this.props.uid,
      datetime: now.toISOString(),
      timestamp: Date.parse(now),
      status: 'open',
    }

    membersRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
      if (snapshot.exists()) {
        let key = Object.keys(snapshot.val())[0];
        let userCreditRef = firebase.database().ref(`members/${key}/credits`);
        userCreditRef.push({credit: itemHash});
      }
    });

    placesRef.orderByChild("pid").equalTo(this.state.place.pid).once('value', (snapshot) => {
      if (snapshot.exists()) {
        let key = Object.keys(snapshot.val())[0];
        let placeCreditRef = firebase.database().ref(`places/${key}/credits`);
        placeCreditRef.push({credit: itemHash});
      }
    });

    creditsRef.push(credit).then((snap) => {
      const content = (<Validator hash={itemHash} />);
      PopupboxManager.open({ content });
    });
  }

  getCert() {
    const content = (<Validator hash={this.state.credit.hash} />);
    PopupboxManager.open({ content });
  }

  getLogo = () => {
    switch(this.state.place.name) {
      case 'Cutbow Coffee':
        return cutbow
        break;
      case 'Little Bear Coffee':
        return littlebear
        break;
      case 'Prismatic Coffee':
        return prismatic
        break;
      case 'Remedy Coffee':
        return remedy
        break;
      case 'Rust Is Gold Coffee':
        return rust
        break;
      case 'Zendo':
        return zendo
        break;
      case 'Zendo at El Vado':
        return zendo
        break;
    }
  }

  componentDidMount = () => {
    const usersRef = firebase.database().ref('members');
    usersRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
      if (snapshot.exists()) {
        var user = snapshot.val();
        var key = Object.keys(snapshot.val())[0];
        var places = user[key]['checkins'];
        base.listenTo(`/members/${key}/credits`, {
          context: this,
          then(credits){
            if( Object.entries(credits).length > 0 ){
              Object.entries(credits).map((credit) => {
                const creditsRef = firebase.database().ref('credits');
                let credkey = credit[1]['credit'];
                creditsRef.orderByChild('hash').equalTo(credkey).once('value', (snapshot) => {
                  if (snapshot.exists()) {
                    var credit = snapshot.val();
                    var key = Object.keys(snapshot.val())[0];
                    credit = credit[key];
                    this.setState({ credit: credit, redeem: true });
                    disloyalPlaces.map((pid) => {
                      if(credit.pid === pid){
                        const placeRef  = firebase.database().ref(`/places/${pid}`);
                        placeRef.once('value', (snapshot) => {
                          let place = snapshot.val();
                          console.log(`Existing Credit @ ${place.name}`);
                          this.setState(place);
                        });
                      }
                    });
                  }
                });
              });
            }
          }
        });
      }
    });
  }

  render() {
    return (
    <div>
      {!this.state.credit && !this.state.redeem && <div style={{paddingTop: '0.5rem'}}>
        {this.state.checked && <div style={{paddingBottom: '0.5rem'}}>
          Are you near participating location?<br />
          Please try again.
        </div>}
        <button className="btn" onClick={() => {this.setState({redeem: true})}}>
          Redeem $5 Credit
        </button>
      </div>}
      {this.state.redeem && !this.state.place && <div className="center">
          { !this.props.isGeolocationAvailable
            ? <p>Your browser does not support Geolocation</p>
            : !this.props.isGeolocationEnabled
              ? <p>Geolocation is not enabled</p>
              : this.props.coords
                && <Async
                   promise={this.getLocale(this.props.coords.latitude,this.props.coords.longitude,this.props.uid)}
                  />
          }
      </div>}
      {this.state.checking &&
        <p>Checking location <i className="fa fa-circle-notch fa-spin white"></i></p>
      }
      {!this.state.credit && this.state.redeem && this.state.place &&
        <div className="center" style={{marginTop: '0.5rem'}}>
          <button className="btn" onClick={() => {this.genCert()}}>
            Use $5 Credit @ <b>{this.state.place.name}</b>?
          </button>
        </div>}
      {this.state.credit && this.state.redeem && this.state.place &&
        <div className="center" style={{marginTop: '0.5rem'}}>
          <button className="btn" onClick={() => {this.getCert()}}>
            Use Existing $5 Credit <br /> @ <b>{this.state.place.name}</b>?
          </button>
        </div>}
    </div>
    )
  }
}

export default geolocated()(Redeem);
