import React from "react";
import firebase from './firebase.js';
import { GeoFire } from 'geofire';
import axios from 'axios';
import Zomato from 'zomato.js';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GemCard from './gemcard.js';
import TrailCard from './trailcard.js';
import Zcard from './zomcard.js';
import _, { shuffle } from 'lodash';

const monsters = [
  'Starbucks',
]

class Suggest extends React.Component {

  constructor() {
    super();
    this.state = {
      gemsexist: false,
      show: false,
      result: false,
      mode: 'gems',
      modeTitle: '',
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  setMode = (str) => {
    this.setState({ mode: str, show: false, result: false });
    this.getListings();
  }

  getIcon = (mode) => {
    switch(mode) {
    case 'food':
        return 'fa-utensils';
    case 'coffee':
        return 'fa-coffee';
    case 'drinks':
        return 'fa-beer';
    case 'trails':
        return 'fa-hiking';
    default:
        break;
    }
  }

  gemsQuery = () => {
    this.setState({modeTitle: 'Gems Nearby'});
    this.setState({hangTitle: 'Checkout:'});
    if(this.props.userkey){
      let usersGeoRef = firebase.database().ref('members-gl');
      let geoUser = new GeoFire(usersGeoRef);
      let placesGeoRef = firebase.database().ref('places-gl');
      let geoPlaces = new GeoFire(placesGeoRef);
      let gems = [];
      geoUser.get(this.props.userkey).then((location) => {
          if (location === null) {
              console.log("Provided key is not in GeoFire");
          } else {
            let geoQuery = geoPlaces.query({
              center: location,
              radius: 4
            });
            geoQuery.on("key_entered", function(key){
              gems.push(key);
            });
            setTimeout(() => {
              if(gems.length > 0){
                this.setState({
                  result: gems,
                  show: true,
                  mode: 'gems',
                  gemsexist: true,
                });
              }else{
                this.setState({ mode: 'food' });
                this.foodQuery();
              }
            },500);
          }
      });
    }
  }

  foodQuery = () => {
    this.setState({modeTitle: 'Places to Eat'});
    this.setState({hangTitle: 'Eat Food'});
    const { lat,lng } = this.props;
    const z = new Zomato('6836545ad5ff91d9a8795ff1d18b7b9c');
    //const z = new Zomato('2c2925ab93a6f8d9b1f4d1a31dfaec54');
    //const z = new Zomato('d1d9c6b0c649fa8b0f4d18ac235db4b5');
    z.geocode({
      lat: ''+lat+'',
      lon: ''+lng+''
    })
    .then((res) => {
      if(res){
        if(res && Object.entries(res.nearby_restaurants).length > 0){
          this.setState({result: res.nearby_restaurants});
          this.setState({show: true});
          return;
        }
      }
    })
    .catch(function(err) {
      console.error(err);
    });
  }

  coffeeQuery = () => {
    this.setState({modeTitle: 'Coffee, Tea and More'});
    this.setState({hangTitle: 'Get Caffeinated'});
    const { lat,lng } = this.props;
    const z = new Zomato('6836545ad5ff91d9a8795ff1d18b7b9c');
    //const z = new Zomato('d1d9c6b0c649fa8b0f4d18ac235db4b5');
    z.search({
      count: '25',
      cuisines: '161',
      lat: ''+lat+'',
      lon: ''+lng+'',
      radius: '3000',
      sort: 'real_distance',
    })
    .then((res) => {
      if(res){
        if(res && Object.entries(res.restaurants).length > 0){
          let places = res.restaurants.filter(function (el) {
            return monsters.indexOf(el.name) === -1;
          });
          this.setState({result: places});
          this.setState({show: true});
          return;
        }
      }
    })
    .catch(function(err) {
      console.error(err);
    });
  }

  drinkQuery = () => {
    this.setState({modeTitle: 'Breweries'});
    this.setState({hangTitle: 'Grab a Drink'});
    const { lat,lng } = this.props;
    const z = new Zomato('6836545ad5ff91d9a8795ff1d18b7b9c');
    //const z = new Zomato('d1d9c6b0c649fa8b0f4d18ac235db4b5');
    z.search({
      count: '25',
      establishment_type: '161',
      lat: ''+lat+'',
      lon: ''+lng+'',
      radius: '1500',
      sort: 'real_distance',
    })
    .then((res) => {
      if(res){
        if(res && Object.entries(res.restaurants).length > 0){
          this.setState({result: res.restaurants});
          this.setState({show: true});
          return;
        }
      }
    })
    .catch(function(err) {
      console.error(err);
    });
  }

  getListings = () => {
    setTimeout(() => {
      if(this.state.mode === 'gems'){
        this.gemsQuery();
      }
      if(this.state.mode === 'coffee'){
        this.coffeeQuery();
      }
      if(this.state.mode === 'food'){
        this.foodQuery();
      }
      if(this.state.mode === 'drinks'){
        this.drinkQuery();
      }
      if(this.state.mode === 'trails'){
        this.trailsQuery();
      }
    },250);
  }

  trailsQuery = () => {
    this.setState({modeTitle: 'Trails and Adventures'});
    this.setState({hangTitle: 'Take A Hike!'});
    const { lat,lng } = this.props;
    let data = {
      key: "200451096-e051eb439fc3563f06602ecc203e8b29",
      lat: lat,
      lon: lng,
      maxDistance: 15,
    };

    let axiosConfig = {
      headers: {
          'X-Requested-With':'XMLHttpRequest'
      }
    };

    axios.get(
      'https://www.hikingproject.com/data/get-trails',
      { params: data },
      axiosConfig)
      .then((res) => {
        this.setState({result: res.data.trails});
        this.setState({show: true});
        return;
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
    });
  }

  componentDidMount = () => {
    this.getListings();
  }

  generateResults = () => {
    const { mode, result } = this.state;
    let shuffled = _.shuffle(result);
    let results = [];
    if(mode === 'gems' && result){
      results = shuffled.map((item, i) => {
        return (
          <GemCard
           lat={this.props.lat}
           lng={this.props.lng}
           key={'gem-card'+item+'-'+i}
           item={item}
           nth={i}
           toggleForm={this.props.toggleForm}
           setLocation={this.props.setLocation}
           setName={this.props.setName}
           setTitle={this.props.setTitle}
           hangTitle={this.state.hangTitle}
          />
        )
      });
    }
    if(mode === 'trails' && result){
      results = shuffled.map((item, i) => {
        return (
          <TrailCard
           lat={this.props.lat}
           lng={this.props.lng}
           key={'gem-card'+item+'-'+i}
           item={item}
           nth={i}
           toggleForm={this.props.toggleForm}
           setLocation={this.props.setLocation}
           setName={this.props.setName}
           setTitle={this.props.setTitle}
           setCategory={this.props.setCategory}
           icon={this.getIcon(this.state.mode)}
           hangTitle={this.state.hangTitle}
          />
        )
      });
    }
    if(mode !== 'gems' && mode !== 'trails'){
      //let items = Object.entries(shuffled).slice(0,5);
      let items = Object.entries(result);
      results = items.map((item, i) => {
        if(item[1].restaurant){
          item = item[1].restaurant;
        }else{
          item = item[1];
        }
        return (
          <Zcard
           lat={this.props.lat}
           lng={this.props.lng}
           key={'zom-card'+item.id+'-'+i}
           item={item}
           nth={i}
           toggleForm={this.props.toggleForm}
           setLocation={this.props.setLocation}
           setName={this.props.setName}
           setTitle={this.props.setTitle}
           setCategory={this.props.setCategory}
           icon={this.getIcon(this.state.mode)}
           hangTitle={this.state.hangTitle}
          />
        )
      });
    }
    return results;
  }

  render() {
    const { mode, modeTitle, result, show } = this.state;
    var settings = {
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            infinite: false,
            arrows: false,
            slidesToShow: 5,
            slidesToScroll: 5,
          }
        },
        {
          breakpoint: 480,
          settings: {
            infinite: false,
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        }
      ]
    };

    return (
      <div>
        <div className="section-header">
          <h4>{modeTitle}</h4>
          <div className="menu">
            {this.state.gemsexist && <i
              onClick={() => {this.setMode('gems')}}
              className={mode === 'gems' ? "fa fa-gem on" : "fa fa-gem"}></i>}
            <i
              onClick={() => {this.setMode('food')}}
              className={mode === 'food' ? "fa fa-utensils on" : "fa fa-utensils"}></i>
            <i
              onClick={() => {this.setMode('coffee')}}
              className={mode === 'coffee' ? "fa fa-coffee on" : "fa fa-coffee"}></i>
            <i
              onClick={() => {this.setMode('drinks')}}
              className={mode === 'drinks' ? "fa fa-beer on" : "fa fa-beer"}></i>
            <i
              onClick={() => {this.setMode('trails')}}
              className={mode === 'trails' ? "fa fa-hiking on" : "fa fa-hiking"}></i>
          </div>
        </div>
        {show && result ?
        <Slider {...settings} className="places-slider">
          {this.generateResults()}
        </Slider>
        : <div className="center page-spinner slick-empty">
          <i className="fa fa-circle-notch fa-spin"></i>
          </div>
        }
      </div>
    );
  }
}

export default Suggest;
