import React from "react";
import firebase, { base } from './firebase.js';
import update from 'immutability-helper';
import moment from 'moment';
import HangMini from './hangmini.js';

// Pond
import { TimeSeries, Index } from "pondjs";
import {
  Baseline,
  Charts,
  ChartContainer,
  ChartRow,
  EventMarker,
  LineChart,
  Resizable,
  ScatterChart,
  YAxis,
} from "react-timeseries-charts";

const NullMarker = props => {
  return <g />;
};

class Place extends React.Component {

  constructor() {
    super();
    this.state = {
      dates: [],
      data: [],
      place: null,
      verified: 0,
      crew: {},
      hang: {},
      tracker: null,
      trackerValue: "-- Hangs",
      trackerEvent: null,
    }
    this.getVerifiedHangs = this.getVerifiedHangs.bind(this);
  }

  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).valueOf() );
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  }

  getHangsByDate = (hangs) => {
    if(hangs){
      //console.log(Object.entries(hangs).length);
      var tstamp = moment().valueOf();
      var pstamp = moment().subtract(14, 'days').valueOf();
      var dates = this.getDates(pstamp, tstamp);
      this.setState({dates});
      dates.map((date, i) => {
        this.setState({
          dates: update(this.state.dates, {[i]: {$set: {date: date}}})
        });
        Object.entries(hangs).map((key) => {
          const hangRef = firebase.database().ref(`/hangs/${key[1]}`);
          let hangkey = key[0];
          hangRef.once('value', (snapshot) => {
            if (snapshot.exists()) {
              let hang = snapshot.val();
              let tomorrow = moment(date).add(1, 'days').valueOf();
              if( date <= hang.timestamp && hang.timestamp <= tomorrow ){
                this.setState({
                  dates: update(this.state.dates, {
                    [i]: {
                      $set: {date: date, hangs: {hang: hang}}
                    }
                  })
                });
              }
            }
          });
        });
      });
      //console.log(this.state.dates);
    }
  }

  getVerifiedHangs = (hangs) => {
    if(hangs){
      let count = 0;
      Object.entries(hangs).map((key) => {
        const hangRef = firebase.database().ref(`/hangs/${key[1]}`);
        let hangkey = key[0];
        hangRef.once('value', (snapshot) => {
          if (snapshot.exists()) {
            let hang = snapshot.val();
            if(hang.validcrew && hang.validhost){
              count++;
              this.setState(prevState => ({
                verified: count,
              }));
              let crew = Object.assign(this.state.crew, hang.crew);
              let owner = {
                [hangkey] : {
                  'uid': hang.uid,
                  'user': hang.user,
                  'userphoto': hang.userphoto,
                  'valid': true,
                }
              }
              crew = Object.assign(this.state.crew, owner);
              this.setState({crew});
            }
          }
        });
      });
    }
  }

  componentDidMount = () => {
    //console.log(this.state.dates);
    if(this.props.id){
      base.listenTo(`/places/${this.props.id}`, {
        context: this,
        then(place){
          this.setState({ place });
          this.getVerifiedHangs(place.hangs);
          this.getHangsByDate(place.hangs);
        }
      });
    }
  }

  handleTrackerChanged = (t, series) => {
      if (t) {
          const e = series.atTime(t);
          const eventTime = new Date(
              e.begin().getTime() + (e.end().getTime() - e.begin().getTime()) / 2
          );
          const eventValue = e.get("temperature");
          const v = `${eventValue > 0 ? "+" : ""}${eventValue}°C`;
          this.setState({ tracker: eventTime, trackerValue: v, trackerEvent: e });
      } else {
          this.setState({ tracker: null, trackerValue: null, trackerEvent: null });
      }
  }

  render() {

    let Hangs = {};

    if(this.state.place && this.state.place.hangs){
      let hangs = this.state.place.hangs;
      Hangs = Object.keys(hangs).map((key) => {
          return (
            <HangMini hang={hangs[key]} />
          )
      });
    }

    let data = [];
    let values = []

    let series = null;

    if(this.state.dates && Object.entries(this.state.dates).length > 0 ){
      Object.entries(this.state.dates).map((item, i) => {
        let date = item[1];
        let time = moment(date.date).format("YYYY-MM-DD HH:mm");
        //console.log(time);
        let count = 0;
        if(date.hangs){
          count = Object.entries(date.hangs).length;
        }
        data.push([time, count]);
        values.push(count);
      });

      series = new TimeSeries({
          name: "hilo_rainfall",
          columns: ["index", "hangs"],
          points: data.map(([d, value]) => [
              Index.getIndexString("1d", new Date(d)),
              value
          ])
      });
    }

    return (
      this.state.place ?
      <div className="place">
          <h1>{this.state.place.name}</h1>
          <hr />
          {this.state.place.hangs ?
          <div>
          <div className="cards">
            <div className="card blue">
              <h2>Hangs Created</h2>
              <span className="data">
              {Object.keys(this.state.place.hangs).length}
              </span>
            </div>
            <div className="card pink">
              <h2>Hangs Verified</h2>
              <span className="data">
              {this.state.verified}
              </span>
            </div>
            <div className="card black">
              <h2>Total Guests</h2>
              <span className="data">
              {Object.keys(this.state.crew).length}
              </span>
            </div>
          </div>
            <h3 style={{textAlign: 'center'}}>Hangs</h3>
            <hr />
            <div>
              {this.state.place &&
              <div className="wrapper hangs">
                {Hangs}
              </div>}
            </div>
            <div style={{padding: '0 1rem'}}>
               <div className="row">
                  <h3 style={{textAlign: 'center'}}>Hangs Over Time</h3>
               </div>
               <hr />
               <div className="row">
                   <div className="col-md-12">
                       {series && <Resizable>
                           <ChartContainer
                            timeRange={series.range()}
                            onTrackerChanged={(t) => {this.handleTrackerChanged(t, series)}}
                           >
                               <ChartRow height="200">
                                   <YAxis
                                       id="hangs"
                                       label="Hangs (day)"
                                       min={0}
                                       max={Math.max.apply(0, values) + 0.5}
                                       format=",.2f"
                                       width="70"
                                       type="linear"
                                       transition={300}
                                       labelOffset={0}
                                   />
                                   <Charts>
                                       <LineChart
                                         axis="hangs"
                                         spacing={1}
                                         columns={["hangs"]}
                                         series={series}
                                       />
                                       <ScatterChart
                                         axis="hangs"
                                         spacing={1}
                                         columns={["hangs"]}
                                         series={series}
                                       />
                                       <Baseline
                                          axis="hangs"
                                          value={0.0}
                                          label="Hangs"
                                       />
                                   </Charts>
                               </ChartRow>
                           </ChartContainer>
                       </Resizable>}
                   </div>
               </div>
           </div>
          </div>:
          <div className="center">No Hangs have been created here yet. 🙁</div>}
      </div>
      : null
    )
  }

}

export default Place;
