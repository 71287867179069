import React from "react";
import ReactGA from 'react-ga';
import Async from 'react-promise';
import firebase from './firebase.js';
import { GeoFire } from 'geofire';
import mmnt from 'moment';
import geocoding from 'reverse-geocoding-google';
import {geolocated} from 'react-geolocated';
import Client from 'predicthq';

class Geolocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: this.props.address,
      movies: []
    }
  }

  // getEventful = (lat, lng) => {
  //   let latlng = lat+','+lng;
  //   window.EVDB.API.call("/events/search/", {
  //     app_key: 'NGCsrxtcNTCCgjfW',
  //     keywords: 'music',
  //     location: latlng,
  //     within: "5km",
  //     date: "This Week",
  //     sort: "date",
  //     change_multi_day_start: false,
  //   }, function(results) {
  //     console.log(results);
  //   });
  // }

  componentDidMount = () => {
    const { userlat, userlng, user } = this.props;
    if(userlat,userlng){
      this.getLocale(userlat,userlng,user,false);
    }
  }

  toggleGetGeo = () => {
    const { coords, lat, lng, userlat, userlng, user } = this.props;
    this.props.setShowNearby(false);
    this.props.setShowSuggest(false);
    if( this.props.getGeo ){
      ReactGA.event({category: 'User', action: 'Getting Home Location'});
      this.props.setGetGeo(false);
      if(userlat,userlng){
        this.getLocale(userlat,userlng,user,false);
      }
    }else{
      ReactGA.event({category: 'User', action: 'Getting Geolocation'});
      this.props.setGetGeo(true);
      if(coords && coords.latitude && coords.longitude){
        this.getLocale(this.props.coords.latitude,this.props.coords.longitude,this.props.user,true);
      }
    }
  }

  uniq(array, prop) {
   var newArray = [];
   var lookupObject  = {};
   for(var i in array) {
     lookupObject[array[i][prop]] = array[i];
   }
   for(i in lookupObject) {
     newArray.push(lookupObject[i]);
   }
   return newArray;
  }

  getLocale = (lat,lng,user,getaddress) => {
    let usersRef = firebase.database().ref('members');
    let usersGeoRef = firebase.database().ref('members-gl');
    let geoUser = new GeoFire(usersGeoRef);
    let results = [];

    usersRef.orderByChild("uid").equalTo(user.uid).once('value', (snapshot) => {
      if (snapshot.exists()) {
        var key = Object.keys(snapshot.val())[0];
        geoUser.set(key, [lat, lng]).then(() => {
            this.props.setGeoLocation({ geoReady: true, lat, lng });
            //this.getEventful(lat, lng);
            //kMliRxAqc49wNf6jmtxJLRBYAqW2Tr
            //GHL45LeU1QhCh7bzT8BiC0hwZ3z8xT
            var client = new Client({access_token: "GHL45LeU1QhCh7bzT8BiC0hwZ3z8xT"});
            let m = mmnt();
            client.events.search({
              'limit': 50,
              'within': '10km@'+lat+','+lng,
              'start.gte': m.add(1, 'day').format('YYYY-MM-DD'),
              'start.lt': m.add(7, 'day').format('YYYY-MM-DD'),
              'relevance':'rank',
              'category':'concerts,festivals,performing-arts,sports','sort':'start'})
            .then((r) => {
                results = r.result.results;
                results = this.uniq(results, "title");
                Object.keys(results).forEach(key => {
                  if (results[key].title.includes('Prayer')) delete results[key];
                });
                this.props.setNearEvents({ results });
                this.props.setShowNearby(true);
                this.props.setShowSuggest(true);
                return;
            }).catch(function(error){
             console.info(error);
             return;
            });
          }, function(error) {
          console.log("Error: " + error);
        });
      }
    });

    if(getaddress){

      var location = false;

      var geocode = {
      'latitude': lat,
      'longitude': lng,
      'key': 'AIzaSyCLpF3Kgl5ILBSREQ2-v_WNxBTuLi1FxXY'
      };

      if(lat && lng && geocode){
        location = async () => {
        return new Promise((resolve, reject) => {
          geocoding.location(geocode, (err, result) => {
              if(err){
                console.log(err);
                reject(err);
                return;
              }else{
                let address = result.results[0].formatted_address;
                if(this.props.address !== address){
                  this.props.setAddress(address);
                  resolve(address);
                }
                resolve();
                return;
              }
              return;
            });
          });
        };
        return location();
      }

    }
  }

  render() {
    return (
    <div>
    {this.props.getGeo ? <div className="user-geolocation">
        { !this.props.address ?
          !this.props.isGeolocationAvailable
          ? <div>Your browser does not support Geolocation</div>
          : !this.props.isGeolocationEnabled
            ? <div>Geolocation is not enabled</div>
            : this.props.coords
              ?
                 <div className="hangs-nearby">
                   <h3>
                     <i className={'fa fa-map-marker'}></i> <span>{this.props.address}</span>
                   </h3>
                 </div>
              : <div className="hangs-nearby"><h3>Getting location <i className="fa fa-circle-o-notch fa-spin"></i></h3></div>
            : <div className="hangs-nearby">
               <h3>
                 <i className={'fa fa-map-marker red'}></i>
                 &nbsp;<span>{this.props.address}</span>&nbsp;
                 <i className={'fa fa-home off'}
                 onClick={()=>{ this.toggleGetGeo() }}></i>
               </h3>
             </div>
        }
    </div> :
    <div className="user-geolocation">
      <div className="hangs-nearby">
         <h3>
           <i className={'fa fa-home red'}></i>
           &nbsp;<span>{this.props.useraddr}</span>&nbsp;
           <i className={'fa fa-map-marker off'}
           onClick={()=>{ this.toggleGetGeo() }}></i>
         </h3>
       </div>
    </div>
    }
    </div>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
    maximumAge: 0,
    timeout: 10000,
  },
  watchPosition: true,
  userDecisionTimeout: 8000,
})(Geolocation);
