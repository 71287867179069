import React from "react";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import VisibilitySensor from 'react-visibility-sensor';
import firebase from './firebase.js';
import geolib from 'geolib';
import fsm from 'fuzzy-string-matching';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import _, { shuffle } from 'lodash';
import Rate from 'rc-rate';

class TrailCard extends React.Component {
  constructor() {
    super();
    this.state = {
      distance: 0,
      location: {}
    }
    this.getPlace = this.getPlace.bind(this);
    this.placeCallback = this.placeCallback.bind(this);
    this.startHang = this.startHang.bind(this);
  }

  startHang = () => {
    const { item } = this.props;
    const { location } = this.state;
    setTimeout(() => {
      this.props.toggleForm();
      this.props.setName(item.name);
      this.props.setTitle(this.props.hangTitle);
      this.props.setLocation(location);
      this.props.setCategory('trails-views');
    },500);
  }

  placeCallback = (results, status) => {
    const { item } = this.props;
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      let result;
      results.map(function(obj){
          let fuzzy = fsm(obj.name, item.name);
          if (fuzzy > 0.015) result = obj;
          return null;
      });
      if(result){
        this.setState({location: {
          formatted_address: result.vicinity,
          place_id: result.place_id,
          geometry: result.geometry,
        }});
      }
    }
  }

  getPlace = (lat,lng) => {
    let maploc = new window.google.maps.LatLng(lat,lng);
    let mapdom = ReactDOM.findDOMNode(this.refs.map);
    let map = new window.google.maps.Map(mapdom, {
        center: maploc,
        zoom: 20
      });
    let service = new window.google.maps.places.PlacesService(map);
    let request = {
      location: maploc,
      radius: '500',
    };
    service.nearbySearch(request, this.placeCallback);
  }

  openPopupbox = () => {
    const { item } = this.props;
    ReactGA.event({category: 'User', action: 'Trail Popup Opened'});
    this.getPlace(item.latitude,item.longitude);
      let height = "300px";
      if( window.innerWidth < 414 ){
        height = "250px";
      }
      if( window.innerWidth < 375 ){
        height = "200px";
      }
      var Image = {
        backgroundImage: "url('"+item.imgSmallMed+"')",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        overflow: "hidden",
        cursor: "pointer",
        height: height,
      }

      let rating = 0;
      rating = item.stars;
      rating = parseInt(rating);

      const content = (
        <div className={'card-popup'}>
        <i className={item.imgMedium ? 'fa fa-times white' : 'fa fa-times'}
        onClick={()=>{PopupboxManager.close()}}>
        </i>
        {item.imgSmallMed && <div style={Image} className={'card-popup-header'}></div>}
        <div className={'card-popup-content'}>
          <h5>{item.name}</h5>
          <div className="small">{this.state.distance}mi</div>
          {rating > 0 && rating &&
            <div>
              <Rate value={rating} allowHalf={true} />
            </div>
          }
          <div className={'card-popup-comments'}>
            <div className='small'>"{item.summary}"</div>
          </div>
        </div>
        <div
          className={'card-popup-bottom'}
          onClick={()=>{this.startHang(); PopupboxManager.close();}}
        >
        <div className={'card-popup-makehang'}>
          <strong>Hang</strong> Here
        </div>
        </div>
      </div>
    );
    PopupboxManager.open({ content });
  }

  componentDidMount = () => {
    const { item, lat, lng } = this.props;
    let distance = geolib.getDistance(
      {latitude: lat, longitude: lng},
      {latitude: item.latitude, longitude: item.longitude}
    );
    distance = (distance/1000) * 0.621371;
    distance = distance.toFixed(1);
    this.setState({distance});
  }

  render() {
    const { item } = this.props;

    var Image = {
      //backgroundColor: "url('"+item.imgMedium+"')",
      backgroundColor: "#000",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      overflow: "hidden",
      height: "144px",
      borderRadius: "0.5rem",
      cursor: "pointer"
    }

    let rating = 0;
    rating = item.stars;
    rating = parseInt(rating);

    return (
      <div>
        <div>
          <div onClick={this.openPopupbox} className="content">
            <div id="map" ref={'map'} />
            <div><i className={`fa ${this.props.icon} white`}></i></div>
            <h4>{item.name}</h4>
            {rating > 0 && rating &&
              <div>
                <Rate value={rating} allowHalf={true} />
              </div>
            }
            <div className="x-small">{this.state.distance}mi</div>
          </div>
          <div style={Image}></div>
        </div>
      </div>
    );
  }
}

export default TrailCard;
