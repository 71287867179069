import React from "react";
import ReactGA from 'react-ga';
import firebase, {base} from './firebase.js';
import {Redirect, Route} from 'react-router-dom';

class Points extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        points: {},
        mode: 'points'
      }
    }

    componentDidMount(){
      ReactGA.event({category: 'User', action: 'Looking at Points'});
      if(this.props.uid){
        const usersRef = firebase.database().ref('members');
        usersRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
          if (snapshot.exists()) {
            var user = snapshot.val();
            var key = Object.keys(snapshot.val())[0];
            base.listenTo(`/members/${key}/points/`, {
              context: this,
              then(points){
                //crew = _.orderBy(crew, ['user'],['asc']);
                this.setState(prevState => ({
                  points: points,
                }));
              }
            });
          }
        });
      }
    }

    render() {
        if (!this.props.uid || this.props.uid === '' || this.props.uid === null) {
           return <Redirect to='/' />
        }

        let points = this.state.points;
        let total = 0;
        let Points = Object.entries(points).map((p,i) => {
          let points = p[1];
          let desc = Object.keys(points);
          let value = Object.values(points);
          total = parseInt(total, 10) + parseInt(value, 10);
          return (
          <tr key={`member-points-${i}`}>
            <td className="small member-points-desc">
              {desc}
            </td>
            <td className="small member-points">
              <strong>{value}</strong>
            </td>
          </tr> )
        });

        return (
            <div className="page-wrapper">
              {this.state.mode === 'points' && <div>
                <div className="fa fa-info-circle nav-btn top-right small"
                   onClick={() => { this.setState({mode:'explain'}) }}
                ></div>
                <h3>Points</h3>
                {this.state.points ?
                  <div>
                  <table className="member-points-table">
                    <thead>
                      <tr>
                        <th>Activity</th>
                        <th className="member-points-head">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Points}
                    </tbody>
                    <tfoot>
                      <tr className="member-points-total-row">
                        <td>Total</td>
                        <td className="member-points-total">{total}</td>
                      </tr>
                    </tfoot>
                  </table>
                  </div>
                : <div className="center page-spinner">
                <i className="fa fa-circle-notch fa-spin"></i>
                </div>}
              </div>}
              {this.state.mode === 'explain' && <div>
                <div className="fa fa-star circle nav-btn top-right small"
                   onClick={() => { this.setState({mode:'points'}) }}
                ></div>
                <h3>Points Explained</h3>
                {this.state.points ?
                  <div>
                  <p className="small">
                    Here are a handful of ways that you can earn points to get
                    cool stuff at&nbsp;
                    <Route render={({history}) => (
                      <span className="underline"
                         onClick={() => { history.push('/marketplace')  }}
                      >The Marketplace</span>
                    )}/>
                  </p>
                  <table className="member-points-table small">
                    <thead>
                      <tr>
                        <th>Activity</th>
                        <th className="member-points-head">Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="left">You've <b>Signed Up</b> as a <b>New User</b></td>
                        <td className="right">100</td>
                      </tr>
                      <tr>
                        <td className="left"><b>Host</b> a <b>Hang</b> and <b>Check-In</b> at <b>Place and Time</b></td>
                        <td className="right">50</td>
                      </tr>
                      <tr>
                        <td className="left"><b>Check-In</b> as a <b>Crew Member</b> in a <b>Group Hang</b></td>
                        <td className="right">25</td>
                      </tr>
                      <tr>
                        <td className="left"><b>Add</b> a new <b>Crew Member</b> to <b>Hangerang</b></td>
                        <td className="right">20</td>
                      </tr>
                      <tr>
                        <td className="left"><b>Check-In</b> as a <b>Crew Member</b> in an <b>Invite-Only Hang</b></td>
                        <td className="right">20</td>
                      </tr>
                      <tr>
                        <td className="left"><b>Check-In</b> as a <b>Crew Member</b> in a <b>Public Hang</b></td>
                        <td className="right">10</td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                : <div className="center page-spinner">
                <i className="fa fa-circle-notch fa-spin"></i>
                </div>}
              </div>}
            </div>
        );
    }
}

export default Points;
