import React from "react";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import {Route} from 'react-router-dom';
import firebase, {base} from './firebase.js';
import { GeoFire } from 'geofire';
import Hashids from 'hashids';
import Moment from 'react-moment';
import geolib from 'geolib';
import { StaticGoogleMap, Marker } from 'react-static-google-map';

var hashids = new Hashids('', 5);

var hangHeader = {
  position: "relative",
  backgroundColor: "#34b6ee",
  margin: "0",
  color: "#fff",
  fontWeight: "300",
  padding: "1rem 5rem 1rem 1rem",
  cursor: "pointer",
  fontFamily: "'Poppins', sans-serif",
  boxSizing: "border-box",
  borderTopLeftRadius: "0.5rem",
  borderTopRightRadius: "0.5rem",
  zIndex: "1",
}

var hangHeaderTitle = {
  fontSize: "1.25rem",
  fontWeight: "600",
  lineHeight: "1",
  marginBottom: "0",
  textTransform: "capitalize",
  fontFamily: "'Poppins', sans-serif",
}

var hangDate = {
  background: "#f41c21",
  color: "#fff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "0",
  right: "0",
  height: "100%",
  padding: "0 1rem",
  textTransform: "uppercase",
  textAlign: "center",
  borderCollapse: "collapse",
  borderTopRightRadius: "0.5rem",
}


var hangMonth = {
  fontSize: "0.75rem",
  lineHeight: "1",
  paddingBottom: "0.33rem",
  display: "block",
}

var hangYear = {
  fontSize: "0.75rem",
  lineHeight: "0",
}

var hangDay = {
  fontFamily: "Josefin Sans, sans-serif",
  fontWeight: "bold",
  fontSize: "1.6rem",
  lineHeight: "0",
}

class OurItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inhang: false,
      key: '',
      hang: '',
      uid: '',
      shortUrl: '',
      mutualFriends: 0,
      openCalendar: false,
      visibility: 'hide'
    }
  }

  claimHang = (title,user,datetime,timestamp,lat,lng,address,place,placename) => {
    ReactGA.event({category: 'User', action: 'Hang Claimed (Ours)'});
    const hangsRef = firebase.database().ref('hangs');
    var key = Date.now();
    key = key.toString().split("").map(num => parseInt(num, 0));
    key = key.splice(8, 5);
    key = key.sort(function(a, b){ return 0.5 - Math.random() });
    const itemHash = hashids.encode(key);
    let category = this.props.hang.category ? this.props.hang.category : '';
    const hang = {
      hash: itemHash,
      title: title,
      uid: this.props.uid,
      user: this.props.username,
      userphoto: this.props.userphoto,
      datetime: datetime,
      timestamp: timestamp,
      lat: lat,
      lng: lng,
      address: address,
      place: place,
      placename: placename,
      visibility: 'invite',
      category: category,
    }
    hangsRef.push(hang).then((snap) => {
     let key = snap.key;
     let geoLocationRef = firebase.database().ref('hangs-gl');
     let geoFire = new GeoFire(geoLocationRef);
     geoFire.set(key, [hang.lat, hang.lng]).then(function() {
       console.log("Hang with key:"+key+" added to database");
       }, function(error) {
       console.log("Error: " + error);
     });
     this.props.setMode('hangs');
   });
   this.setState({
     hangKey: key,
   });
   const newPlace = {
     pid: hang.place,
     name: hang.placename,
   }
   const placeRef = firebase.database().ref('places');
   placeRef.orderByChild("pid").equalTo(place).once('value', (snapshot) => {
       if (snapshot.exists()) {
         var place = snapshot.val();
         let key = Object.keys(place)[0];
         const placeHangsRef = firebase.database().ref(`/places/${key}/hangs/`);
         Object.entries(place).map((p) => {
            placeHangsRef.push(this.state.hangKey);
            return console.log("p");
         });
         console.log('Place exists. Add new hang');
         return;
       }else{
         placeRef.push(newPlace).then((snap) => {
           const newPlaceHangsRef = firebase.database().ref(`/places/${snap.key}/hangs/`);
           newPlaceHangsRef.push(this.state.hangKey);
         });
         console.log('Place created in database. First Hang added');
         return;
       }
   }).catch(function(error) {
     console.log(error);
   });
  }

  placeCallback = (results, status) => {
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      if(results[1]){
        if(results[1].photos && results[1].photos.length > 0){
          var placePhotoUrl = results[1].photos[0].getUrl({maxWidth:640});
          this.setState({placeimg: placePhotoUrl});
        }
        this.setState({placeid: results[1]['id']});
        this.setState({placename: results[1]['name']});
      }
    }
  }

  getPlace = (lat,lng) => {
    let maploc = new window.google.maps.LatLng(lat,lng);
    let mapdom = ReactDOM.findDOMNode(this.refs.map);

    let map = new window.google.maps.Map(mapdom, {
        center: maploc,
        zoom: 15
      });

    let request = {
      location: maploc,
      radius: '1'
    };

    let service = new window.google.maps.places.PlacesService(map);
    service.nearbySearch(request, this.placeCallback);
  }

  removeHang(id) {
    base.remove(`/hangs/${id}`);
    this.props.onHangChange(id);
  }

  componentDidMount = (result) => {
    if(this.props.geoReady){
      //this.getPlace(this.props.hang.lat,this.props.hang.lng);
      let distance = geolib.getDistance(
        {latitude: this.props.geoReady.lat, longitude: this.props.geoReady.lng},
        {latitude: this.props.hang.lat, longitude: this.props.hang.lng}
      );
      distance = (distance/1000) * 0.621371;
      distance = distance.toFixed(1);
      this.setState({distance});
    }
  }

  toggleInfo () {
    if(!this.state.showdesc){
      ReactGA.event({category: 'User', action: 'Looking at GhostHang Info'});
    }
    this.setState({showdesc: !this.state.showdesc})
  }

  mapCategory = (str) => {
    switch(str) {
    case 'food-drink':
        return 'Food & Drink';
    case 'arts-crafts':
        return 'Arts & Crafts';
    case 'music-concerts':
        return 'Music & Concerts';
    case 'trails-views':
        return 'Trails & Views';
    case 'stage-screen':
        return 'Stage & Screen';
    case 'sports-fitness':
        return 'Sports & Fitness';
    case 'help-giving':
        return 'Help & Giving';
    case 'words-stories':
        return 'Words & Stories';
    case 'games-trivia':
        return 'Games & Trivia';
    default:
        break;
    }
  }

  render() {
    var hangLink = '/hang/'+this.props.hang.hash;

    let imgUrl = '';
    if(this.state.edit){
      //Do nothing;
    }else if(this.props.hang.image){
      imgUrl = this.props.hang.image;
    }else{
      imgUrl = this.state.placeimg;
    }

    var hangImage = {
      backgroundImage: "url('"+imgUrl+"')",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      overflow: "hidden",
      height: "250px",
      borderBottomLeftRadius: "0.5rem",
      borderBottomRightRadius: "0.5rem",
    }
    let Hang =
      <span>
        <span ref="detail">
        <table width="100%" style={hangHeader} className={'hang-header'}>
          <tbody>
          <tr>
            <td>
              <h2 style={hangHeaderTitle}>{this.props.hang.title}</h2>
              <span className="hang-placetime">
                <Moment format="hh:mm a" className="hang-time">{this.props.hang.datetime}</Moment> @ {this.props.hang.placename}
              </span>
              {this.props.hang.category && <div>
                <div className={'capsule small '+this.props.hang.category}>
                  {this.mapCategory(this.props.hang.category)}
                </div>
              </div>}
            </td>
            <td>
              <table style={hangDate}>
                <tbody>
                <tr>
                  <td>
                <Moment format="MMM" className="hang-month" style={hangMonth}>{this.props.hang.datetime}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                <Moment format="DD" className="hang-day" style={hangDay}>{this.props.hang.datetime}</Moment>
                  </td>
                </tr>
                <tr>
                  <td>
                <Moment format="YYYY" className="hang-year" style={hangYear}>{this.props.hang.datetime}</Moment>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
          </tbody>
        </table>
        <div className="hang-item-graphic" style={hangImage}>
          <div className="hang-actions">
            {this.props.hang.uid !== this.props.user.uid &&
            <button
              className="btn-hang-action"
              onClick={() =>
                this.claimHang(
                  this.props.hang.title,
                  this.props.user,
                  this.props.hang.datetime,
                  this.props.hang.timestamp,
                  this.props.hang.lat,
                  this.props.hang.lng,
                  this.props.hang.address,
                  this.props.hang.place,
                  this.props.hang.placename,
                )
              }>
              <i className="fa fa-plus"></i> Go
            </button>}
            {this.props.hang.uid === this.props.user.uid &&
              <Route render={({history}) => (
                <button
                  className="btn-hang-action pink"
                  onClick={() => {history.push(hangLink)}}>
                  <i className="fa fa-eye white"></i><br /> Details
                </button>
              )}/>
            }
          </div>
        {!this.state.showdesc && this.props.hang.description &&
          <div
            className="btn-hang-info"
            onClick={(e) =>
              this.toggleInfo()
            }>
            <i className={this.state.placeimg ? "fa fa-info-circle" : "fa fa-info-circle blue"}></i>
          </div>
        }
        {!this.props.hang.image && !this.state.placeimg && !this.state.showdesc &&
        <div>
          <a target="_blank" href={'https://www.google.com/maps/search/?api=1&query='+this.props.hang.lat+'%2C'+this.props.hang.lng+'&query_place_id='+this.props.hang.place}>
            <StaticGoogleMap
              ref={'map'}
              size={this.props.mapsize}
              center={this.props.hang.lat+','+this.props.hang.lng}
              zoom="18"
              apiKey="AIzaSyCLpF3Kgl5ILBSREQ2-v_WNxBTuLi1FxXY"
              style={"element:geometry%7Ccolor:0xebe3cd&style=element:labels.text.fill%7Ccolor:0x523735&style=element:labels.text.stroke%7Ccolor:0xf5f1e6&style=feature:administrative%7Celement:geometry.stroke%7Ccolor:0xc9b2a6&style=feature:administrative.land_parcel%7Celement:geometry.stroke%7Ccolor:0xdcd2be&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xae9e90&style=feature:landscape.natural%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:poi%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x93817c&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0xa5b076&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x447530&style=feature:road%7Celement:geometry%7Ccolor:0xf5f1e6&style=feature:road.arterial%7Celement:geometry%7Ccolor:0xfdfcf8&style=feature:road.highway%7Celement:geometry%7Ccolor:0xf8c967&style=feature:road.highway%7Celement:geometry.stroke%7Ccolor:0xe9bc62&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0xe98d58&style=feature:road.highway.controlled_access%7Celement:geometry.stroke%7Ccolor:0xdb8555&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x806b63&style=feature:transit.line%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:transit.line%7Celement:labels.text.fill%7Ccolor:0x8f7d77&style=feature:transit.line%7Celement:labels.text.stroke%7Ccolor:0xebe3cd&style=feature:transit.station%7Celement:geometry%7Ccolor:0xdfd2ae&style=feature:water%7Celement:geometry.fill%7Ccolor:0xb9d3c2&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x92998d&size=480x360"}
            >
              <Marker location={this.props.hang.lat+','+this.props.hang.lng} color="0xff0000" />
            </StaticGoogleMap>
          </a>
        </div>
        }
        {this.state.showdesc &&
          <div>
            <div className="hang-item-desc-wrapper">
              <div className="hang-item-desc">
                {this.props.hang.description}
              </div>
            </div>
            <div
              className="hang-item-desc-close"
              onClick={(e) =>
                this.toggleInfo()
              }>
              <i className="fas fa-times"></i>
          </div>
          </div>
        }
        </div>
        </span>
      </span>;

    let OurItem = <div className={'hang-item show'} key={this.state.key}>{Hang}</div>;

    return OurItem;

  }
}

export default OurItem;
