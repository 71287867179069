import React from "react";
import ReactGA from 'react-ga';
import firebase, { base } from './firebase.js';
import {Redirect, Route} from 'react-router-dom';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';

class Marketplace extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        items: {},
        total: 0,
        mode: 'browse',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        item: '',
        ordered: false,
      }
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    makeOrder = () => {
      const { item, street1, street2, city, state, zip } = this.state;
      const { userkey } = this.props;
      const itemsRef = firebase.database().ref(`items`);
      const itemRef = firebase.database().ref(`items/${item.key}`);
      const ordersRef = firebase.database().ref(`orders`);
      const pointsRef = firebase.database().ref(`members/${this.props.userkey}/points`);
      const memOrdersRef = firebase.database().ref(`members/${this.props.userkey}/orders`);

      let points = {};
      let key = 'Marketplace: '+item.name;
      points[key] = item.points * -1;
      pointsRef.push(points);

      let order = {
        user: userkey,
        item: item.key,
        date: new Date().getTime(),
      }
      ordersRef.push(order).then((snap) => {
       let key = snap.key;
       let mOrder = {
         order: key
       }
       memOrdersRef.push(mOrder);
      });

      let itemCount = item.availability - 1;
      itemRef.update({availability: itemCount});

      this.setState({ ordered: true });
    }

    saveAddr = () => {
      const {
        street1,
        street2,
        city,
        state,
        zip,
      } = this.state;

      const memberRef = firebase.database().ref(`members/${this.props.userkey}`);
      let address = {
        mailaddr: {
          street1: street1,
          street2: street2,
          city: city,
          state: state,
          zip: zip,
        }
      }
      memberRef.update(address);
      this.setState({addrsaved: true});
    }

    componentDidMount(){
      ReactGA.event({category: 'User', action: 'Looking at Marketplace'});
      if(this.props.usermail){
        const {usermail} = this.props;
        this.setState({
          street1: usermail.street1,
          street2: usermail.street2,
          city: usermail.city,
          state: usermail.state,
          zip: usermail.zip,
        });
      }
      const itemsRef = firebase.database().ref('items');
      base.bindToState(`items`, {
        context: this,
        state: 'items',
        asArray: true,
        keepKeys: true,
        }
      );
      const { userkey } = this.props;
      if(userkey){
        const usersRef = firebase.database().ref(`members/${userkey}/points/`);
        usersRef.once('value', (snapshot) => {
          if (snapshot.exists()) {
            let points = snapshot.val();
            if(points){
              let total = 0;
              Object.entries(points).map((p,i) => {
                let points = p[1];
                let value = Object.values(points);
                total = parseInt(total, 10) + parseInt(value, 10);
                this.setState({total});
              });
            }
          }
        });
      }
    }

    openPopupBox = (item, image) => {
      ReactGA.event({category: 'User', action: 'Marketplace Spend Popup Opened'});
      const { total, street1, city, state, zip } = this.state;
      let content = (
        <div className={'card-popup'}>
          <i className={image ? 'fa fa-times white' : 'fa fa-times'}
          onClick={()=>{PopupboxManager.close()}}>
          </i>
          {image && <div style={image} className={'card-popup-header'}></div>}
          <div className={'card-popup-content'}>
            <strong>{item.name}</strong>
            <div className="small">
              <strong>{item.points}</strong> points
            </div>
            <div className="small">
              You have <strong>{this.state.total}</strong> points to spend.
            </div>
            {this.state.total - item.points > 0 ?
              <div className="small">
                You'll be left with <strong>
                {total - item.points}
                </strong> points.
              </div>:
              <div><hr /><strong className="small">
                Sorry, you don't have enough points.
              </strong></div>}
          </div>
          {total - item.points > 0 &&
          <div>
          {street1 && city && state && zip ?
          <div
            className={'card-popup-makehang'}
            onClick={() => {
              this.setState({
                mode: 'review',
                item: item,
                image: image,
              });
              PopupboxManager.close();
            }}
          >
            <strong>Cool, I want it!</strong>
          </div> :
          <div
            className={'card-popup-makehang'}
            onClick={() => {
              this.setState({
                mode: 'address',
                item: item,
                image: image,
              });
              PopupboxManager.close();
            }}
          >
            <strong>Cool, I want it!</strong>
          </div>}
        </div>}
        {this.state.total - item.points < 0 &&
          <div
            className={'card-popup-makehang'}
            onClick={() => {
              PopupboxManager.close();
            }}
          >
            <strong>Close Window</strong>
          </div>
        }
        </div>
      );
      PopupboxManager.open({ content });
    }

    render() {
        if (!this.props.userkey || this.props.userkey === '' || this.props.userkey === null) {
           return <Redirect to='/' />
        }

        const {
          addrsaved,
          street1,
          street2,
          city,
          state,
          zip,
          mode,
          item,
          items,
          image,
          total,
          ordered,
        } = this.state;

        const {
          useraddr,
        } = this.props;

        let Items = [];

        if(items && items.length > 0){
          Items = items.map((item,i) => {
            let Image = {
              backgroundImage: "url('"+item.image+"')",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              overflow: "hidden",
              height: "250px",
            }
            return (
            <div className="item" key={`item-${i}`}>
              <div style={Image}></div>
              <div className="item-info">
                <h5 className="item-header">{item.name}</h5>
                <div className="small center">{item.provider}</div>
                <div className="small center">
                  <strong>{item.availability}</strong> available
                </div>
                <hr />
                <div className="small">{item.description}</div>
                <div className="item-params">
                </div>
              </div>
              <a
                className="btn blue item-btn"
                onClick={() => {this.openPopupBox(item,Image)}}
              >
                Spend &nbsp;<strong>{item.points}</strong> &nbsp; points
              </a>
            </div> );
          });
        }

        return (
            <div className="wrapper">
              <h3 className="center">Marketplace</h3>
              {mode === 'browse' && <div>
                {items && items.length > 0 ?
                  <div className="items">{Items}</div>
                : <div className="center page-spinner">
                <i className="fa fa-circle-notch fa-spin middle"></i>
                </div>}
              </div>}
              {mode === 'address' &&
                <div className={'page-wrapper'}>
                  <div>
                    To get <strong>{item.name}</strong><br />
                    please enter your <br /><strong>Mailing Address</strong>
                  </div>
                  <MuiThemeProvider>
                    <div className="addr-fields">
                      <TextField className={"input input-street1"} type="text" name="street1" placeholder="Street 1" onChange={this.handleChange} value={this.state.street1} />
                      <TextField className={"input input-street2"} type="text" name="street2" placeholder="Street 2" onChange={this.handleChange} value={this.state.street2} />
                      <TextField className={"input input-city"} type="text" name="city" placeholder="City" onChange={this.handleChange} value={this.state.city} />
                      <TextField className={"input input-state"} type="text" name="state" placeholder="State" onChange={this.handleChange} value={this.state.state} />
                      <TextField className={"input input-zip"} type="text" name="zip" placeholder="ZipCode" onChange={this.handleChange} value={this.state.zip} />
                    </div>
                  </MuiThemeProvider>
                  {street1 && city && state && zip &&
                  <div>
                    {addrsaved ? <div onClick={() => {this.setState({ mode: 'review' })}} className={'card-popup-makehang'}>
                      <strong>Address Saved! Continue?</strong></div>
                      : <div onClick={() => {this.saveAddr()}} className={'card-popup-makehang'}>
                      <strong>Save Address</strong></div>}
                  </div>}
              </div>}
              {mode === 'review' &&
                <div className={'page-wrapper'}>
                  <h3>Order Review</h3>
                  <hr />
                  <div className="small">
                    We will send <br />
                    <strong>{item.name}</strong><br />
                    to:<br />
                    <strong>{street1+' '+street2}<br />
                    {city+', '+state}<br />
                    {zip}</strong>
                    <hr />
                    You will spend <br />
                    <strong>{item.points}</strong> of your <strong>{total}</strong> points
                    <hr />
                  </div>
                  {ordered ?
                    <Route render={({history}) => (
                      <div className={'card-popup-makehang'}
                         onClick={() => {history.push('/orders') }}
                      >
                      <strong>Done! View Order!</strong>
                      </div>
                    )}/> : <div className={'card-popup-makehang'}
                    onClick={() => {this.makeOrder()}}>
                    <strong>Complete Order</strong>
                  </div>}
                  <a
                    className={'small underline'}
                    onClick={() => {this.setState({ mode: 'browse' })}}>
                    Browse Marketplace
                  </a>
              </div>}
            </div>
        );
    }
}

export default Marketplace;
