import React from "react";
import firebase, {base} from './firebase.js';
import {Redirect, Route} from 'react-router-dom';

class PlacesHero extends React.Component {

  constructor() {
    super();
    this.state = {
      places: {}
    }
  }

  removePlace(id) {
    base.remove(`/places/${id}`);
    const usersRef = firebase.database().ref('members');
    usersRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
      if (snapshot.exists()) {
        var key = Object.keys(snapshot.val())[0];
        const usersPlacesRef = firebase.database().ref(`/members/${key}/places`);
        usersPlacesRef.orderByChild("id").equalTo(id).once('value', (snapshot) => {
              if (snapshot.exists()) {
                var place = Object.keys(snapshot.val())[0];
                base.remove(`/members/${key}/places/${place}`);
              }
          });
      }
    });
  }

  componentDidMount = () => {
    console.log(this.props.uid);
    if(this.props.uid){
      const memberRef = firebase.database().ref(`/members/`);
      memberRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
        if (snapshot.exists()) {
          let member = snapshot.val();
          let key = Object.keys(snapshot.val())[0];
          console.log(key);
          base.listenTo(`members/${key}/places/`, {
            context: this,
            then(places){
              this.setState(prevState => ({
                places: places,
              }));
            }
          });
        }
      });
    }
  }

  render() {
    if (!this.props.uid || this.props.uid === '' || this.props.uid === null) {
       return <Redirect to='/' />
    }

    let Places = Object.entries(this.state.places).map((p) => {
      let key = p[0];
      let place = p[1];
      return (
      <tr className="groups-row" key={`group-${key}`}>
        <td className="group-name">
          <strong>{place.name}</strong>
        </td>
        <td className="group-options">
          <Route render={({history}) => (
            <i className="fa fa-edit"
               onClick={() => {history.push('/place/'+place.place)}}
            ></i>
          )}/>
          <i className="fas fa-times"
             onClick={() => this.removePlace(key)}></i>
        </td>
      </tr>
      )
    });

    return (
      <div className="page-wrapper places">
          <h3>Your Places</h3>
          <hr />
          {this.state.places &&
            <table className="groups-table">
              <tbody>{Places}</tbody>
            </table>
          }
      </div>
    )
  }

}

export default PlacesHero;
