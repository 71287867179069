import React from "react";
import ReactDOM from 'react-dom';
import firebase, { base } from './firebase.js';
import { withRouter } from 'react-router-dom';
import geolib from 'geolib';
import cutbow from '../assets/crawl/cutbow.png';
import humble from '../assets/crawl/humble.png';
import littlebear from '../assets/crawl/littlebear.png';
import prismatic from '../assets/crawl/prismatic.png';
import remedy from '../assets/crawl/remedy.png';
import rust from '../assets/crawl/rustisgold.png';
import villamyriam from '../assets/crawl/villamyriam.png';
import zendo from '../assets/crawl/zendo.png';
import memphis from '../assets/memphis-grey.jpg';
import sky from '../assets/sky.jpg';
import coffee from '../assets/coffee-bkgd.jpg';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import _, { shuffle } from 'lodash';
import Redeem from './redeem.js';

/*
Cutbow: -LiLz6MJUJSBhI4IdSel
Humble Downtown: -LKXsfeTzOmVmHsEoWxD
Humble Lomas: -LKyK8Vq4Z6mqjbJo3-v
Little Bear: -LKyKDjkGtUb0PYMy4oU
Villa Myriam: -LKyKNoL1Yt8tN6y-5KO
The Brew: -LKyKYI0sPo_XYKMTJMD
Zendo: -LKyKgci1oueqrdngpKQ
Prismatic: -LKyKmgEGbiR2WPyYD4H
Rust Is Gold: -LiE1jvLzienlBxLsiDI
*/

const disloyalPlaces = [
// '-LKXsfeTzOmVmHsEoWxD',
// '-LKyK8Vq4Z6mqjbJo3-v',
// '-LKyKNoL1Yt8tN6y-5KO',
// '-LKyKYI0sPo_XYKMTJMD',
'-LiLz6MJUJSBhI4IdSel',
'-LKyKDjkGtUb0PYMy4oU',
'-LKyKmgEGbiR2WPyYD4H',
'-LkNMw6Fe_oOiKk5wNug',
'-LiE1jvLzienlBxLsiDI',
'-LKyKgci1oueqrdngpKQ',
'-Lg-ryjHaSRZfBc-ixfD',
]

class Crawl extends React.Component {
    constructor() {
      super();
      this.state = {
        cutbow: false,
        littlebear: false,
        prismatic: false,
        remedy: false,
        rust: false,
        zendo: false,
        place: '',
        location: {}
      }
    }

    componentDidMount = (result) => {
      const usersRef = firebase.database().ref('members');
      usersRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
        if (snapshot.exists()) {
          var user = snapshot.val();
          var key = Object.keys(snapshot.val())[0];
          var places = user[key]['checkins'];
          base.listenTo(`/members/${key}/checkins`, {
            context: this,
            then(places){
              this.setState({
                cutbow: false,
                littlebear: false,
                prismatic: false,
                remedy: false,
                rust: false,
                zendo: false
              });
              Object.keys(places).forEach((p) => {
                let place = places[p];
                if(place.pid === '-LiLz6MJUJSBhI4IdSel'){
                  this.setState({cutbow: true});
                }else if(place.pid === '-LKyKDjkGtUb0PYMy4oU'){
                  this.setState({littlebear: true});
                }else if(place.pid === '-LKyKmgEGbiR2WPyYD4H'){
                  this.setState({prismatic: true});
                }else if(place.pid === '-LkNMw6Fe_oOiKk5wNug'){
                  this.setState({remedy: true});
                }else if(place.pid === '-LiE1jvLzienlBxLsiDI'){
                  this.setState({rust: true});
                }else if(place.pid === '-LKyKgci1oueqrdngpKQ' ||
                  place.pid === '-Lg-ryjHaSRZfBc-ixfD'){
                  this.setState({zendo: true});
                }
              });
            }
          });
        }
      });
    }

    startHang = () => {
      const { location, place } = this.state;
      setTimeout(() => {
        this.props.history.push('/')
        this.props.toggleForm();
        this.props.setLocation(location);
        this.props.setName(place.name);
        this.props.setTitle('Coffee Hang @ '+place.name);
      },500);
    }

    getPlace = (id,pid) => {
      const { lat, lng } = this.props;
      const placeGLRef = firebase.database().ref(`/places-gl/${pid}`);
      placeGLRef.once('value', (snapshot) => {
        if(snapshot.val()){
          let pgl = snapshot.val();
          let distance = geolib.getDistance(
            {latitude: lat, longitude: lng},
            {latitude: pgl['l'][0], longitude: pgl['l'][1]}
          );
          distance = (distance/1000) * 0.621371;
          distance = distance.toFixed(1);
          this.setState({distance});
        }
      });
      let maploc = new window.google.maps.LatLng(lat,lng);
      let mapdom = ReactDOM.findDOMNode(this.refs.map);
      let map = new window.google.maps.Map(mapdom, {
          center: maploc,
          zoom: 20
        });
      let service = new window.google.maps.places.PlacesService(map);
      service.getDetails({
          placeId: id
      }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          if(result.photos && result.photos.length > 0){
            var placePhotoUrl = result.photos[0].getUrl({maxWidth:630});
            this.setState({placeimg: placePhotoUrl});
            if(result.opening_hours){
              this.setState({
                open: result.opening_hours.open_now,
                website: result.website,
                url: result.url,
                address: result.vicinity
              });
            }
            this.setState({location: {
              formatted_address: result.vicinity,
              place_id: result.place_id,
              geometry: result.geometry,
            }});
          }
        }
      });
    }

    openPopupbox = (pid) => {
      this.setState({
        place: '',
        placeimg: '',
        open: '',
        website: '',
        url: '',
        address: '',
        formatted_address: '',
        place_id: '',
        geometry: '',
        distance: ''
      });
      const placeRef = firebase.database().ref(`/places/${pid}`);
      placeRef.once('value', (snapshot) => {
        let placekey = Object.keys(snapshot.val())[0];
        let place = snapshot.val();
        let height = "300px";
        if( window.innerWidth < 414 ){
          height = "250px";
        }
        if( window.innerWidth < 375 ){
          height = "200px";
        }
        this.setState({ place });
        this.getPlace(place.pid, pid);
        setTimeout( () => {
            var Image = {
              backgroundImage: "url('"+this.state.placeimg+"')",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              overflow: "hidden",
              cursor: "pointer",
              height: height,
            }
            const content = (
              <div className={'card-popup'}>
                <i className={this.state.placeimg ? 'fa fa-times white' : 'fa fa-times'}
                onClick={()=>{PopupboxManager.close()}}>
                </i>
                <div style={Image} className={'card-popup-header'}></div>
                <div className={'card-popup-content'}>
                  <h5>{place.name}</h5>
                  <div><i onClick={() => {this.handleURL(this.state.url)}} className={'fa fa-map-marker small'}></i></div>
                  <div className="x-small">
                    <strong>{this.state.address}</strong>
                  </div>
                  <div className="small">{this.state.distance}mi</div>
                  {this.state.open !== null && <div className={this.state.open ? "small open-status green" : "small open-status red white"}>
                    {this.state.open ? 'Open Now' : 'Not Open Now'}
                  </div>}
                </div>
                <div
                  className={'card-popup-bottom'}
                  onClick={()=>{this.startHang(); PopupboxManager.close();}}
                >
                  <div className={'card-popup-makehang'}>
                    <strong>Hang</strong> Here
                  </div>
                </div>
              </div>
            );
            PopupboxManager.open({ content });
        },500);
      });
    }

    render() {
        var zendos = [
          '-LKyKgci1oueqrdngpKQ',
          '-Lg-ryjHaSRZfBc-ixfD',
        ];

        return (
            <div
              className="page-wrapper"
              style={{
                background: "url('"+coffee+"')",
                backgroundSize: 'cover',
                backgroundPosition: 'center bottom',
                maxWidth: 'calc(100% - 2rem)',
                padding: '1rem 0',
                boxShadow: '5px 5px 5px rgba(0,0,0,0.25)'
              }}>
              <i class="fas fa-mug-hot white"></i>
              <h1
              className="no-margin uppercase"
              style={{lineHeight: '30px', color: '#90ebff'}}>
                Disloyalty <br />Card
              </h1>
              <hr />
              <div className={'small'} style={{color: '#fff'}}>(Click logos for info)</div>
              <div className={'locations'}>
                <div id="cutbow" className={this.state.cutbow ? 'checked' : ''}>
                  <a onClick={() => { this.openPopupbox('-LiLz6MJUJSBhI4IdSel') }} target="blank">
                    <img
                      src={cutbow}
                      alt="Cutbow Coffee"
                    />
                  </a>
                </div>
                <div id="littlebear" className={this.state.littlebear ? 'checked' : ''}>
                  <a onClick={() => { this.openPopupbox('-LKyKDjkGtUb0PYMy4oU') }} target="blank">
                    <img
                      src={littlebear}
                      alt="Little Bear Coffee"
                    />
                  </a>
                </div>
                <div id="prismatic" className={this.state.prismatic ? 'checked' : ''}>
                  <a onClick={() => { this.openPopupbox('-LKyKmgEGbiR2WPyYD4H') }} target="blank">
                    <img src={prismatic} alt="Prismatic Coffee" />
                  </a>
                </div>
                <div id="remedy" className={this.state.remedy ? 'checked' : ''}>
                  <a onClick={() => { this.openPopupbox('-LkNMw6Fe_oOiKk5wNug') }} target="blank">
                    <img src={remedy} alt="Remedy Coffee" />
                  </a>
                </div>
                <div id="rust" className={this.state.rust ? 'checked' : ''}>
                  <a onClick={() => { this.openPopupbox('-LiE1jvLzienlBxLsiDI') }} target="blank">
                    <img src={rust} alt="Rust Is Gold" />
                  </a>
                </div>
                <div id="zendo" className={this.state.zendo ? 'checked' : ''}>
                  <a onClick={() => { this.openPopupbox(zendos[Math.floor(Math.random() * zendos.length)]) }} target="blank">
                    <img src={zendo} alt="Zendo Coffee" />
                  </a>
                </div>
              </div>
              <hr />
              {this.state.cutbow &&
              this.state.littlebear &&
              this.state.prismatic &&
              this.state.remedy &&
              this.state.rust &&
              this.state.zendo ?
              <div style={{padding: '0 2rem', color: '#fff'}}>
                <div>
                  <b>You did it!</b><br />
                  Time to <b>treat yo self!</b>
                </div>
                <Redeem uid={this.props.uid} />
              </div>
              : <div style={{padding: '0 2rem', color: '#fff'}}><span><p className={'small'}><b>Hang</b> and <b>Check-In</b>
              &nbsp; at any of these fine local coffee places.</p>
              <p className={'small'}>Check-In at all <b>6</b> and get a
              &nbsp;<b>free item*</b> at any location on your next visit</p></span></div>}
              <span className={'x-small'} style={{color: '#90ebff'}}>* Item $5 or less in value</span>
              <div id="map" ref={'map'} />
            </div>
        );
    }
}

export default withRouter(Crawl);
