import React from "react";
import ReactGA from 'react-ga';
import firebase, { base } from './firebase.js';
import {Redirect} from 'react-router-dom';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Order from './order.js';

class Orders extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        orders: {},
        total: 0,
        mode: 'browse',
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: '',
        order: '',
        ordered: false,
        timeout: false,
      }
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    componentDidMount(){
      base.bindToState(`/orders`, {
        context: this,
        state: 'orders',
        asArray: true,
      });
      setTimeout(()=>{
        this.setState({timeout: true});
      },3000);
    }

    render() {
        const {
          orders,
          timeout,
        } = this.state;

        if (timeout && this.props.userkey !== '-LJpKswYDeGiDUy46FuM') {
           return <Redirect to='/' />
        }

        let Orders = [];

        if(orders && orders.length > 0){
          Orders = orders.map((order,i) => {
            return ( <Order orderkey={order.key} admin /> )
          });
        }

        return (
            <div className="page-wrapper">
              <h3 className="center">Orders Admin</h3>
              <div>
                {orders && orders.length > 0 ?
                  <table className="orders small">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Date</th>
                        <th>Status</th>
                        {/*<th>Action</th>*/}
                      </tr>
                    </thead>
                    <tbody>
                      {Orders}
                    </tbody>
                  </table>
                : <div className="center page-spinner">
                <i className="fa fa-circle-notch fa-spin middle"></i>
                </div>}
              </div>
            </div>
        );
    }
}

export default Orders;
