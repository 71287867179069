import React from "react";
import ReactGA from 'react-ga';
import firebase from './firebase.js';
import moment from 'moment';
import QrReader from 'react-qr-reader';
import mmnt from 'moment';
import Moment from 'react-moment';

import { getPoints } from '../helpers/points.js';

import fail from '../assets/fail.svg';
import valid from '../assets/validation.svg';

/*
Cutbow: -LiLz6MJUJSBhI4IdSel
Little Bear: -LKyKDjkGtUb0PYMy4oU
Prismatic: -LKyKmgEGbiR2WPyYD4H
Remedy: -LkNMw6Fe_oOiKk5wNug
Rust Is Gold: -LiE1jvLzienlBxLsiDI
Zendo: -LKyKgci1oueqrdngpKQ
Zendo at El Vado: -Lg-ryjHaSRZfBc-ixfD
*/

const disloyalPlaces = [
'-LiLz6MJUJSBhI4IdSel',
'-LKyKDjkGtUb0PYMy4oU',
'-LKyKmgEGbiR2WPyYD4H',
'-LkNMw6Fe_oOiKk5wNug',
'-LiE1jvLzienlBxLsiDI',
'-LKyKgci1oueqrdngpKQ',
'-Lg-ryjHaSRZfBc-ixfD',
]

const baseUrl = window.location.protocol + "//" + window.location.host;

class Validator extends React.Component {
    constructor() {
      super();
      this.state = {
        delay: 300,
        result: 'No result',
        validURL: false,
        credit: null,
        place: null,
        user: null,
        checking: true,
        checked: false,
        processed: false,
      }
      this.handleScan = this.handleScan.bind(this)
    }

    reverseString(str) {
      return str.split("").reverse().join("");
    }

    handleScan(data){
      if(data && disloyalPlaces.includes(this.reverseString(data)) !== -1){
        this.setState({
          validURL: true
        })
      }
    }

    handleError(err){
      console.error(err)
    }

    processCredit = () => {
      const { hash } = this.props;
      const creditRef = firebase.database().ref(`/credits/`);
      creditRef.orderByChild("hash").equalTo(hash).once('value', (snapshot) => {
        if(snapshot.val()){
          let key = Object.keys(snapshot.val())[0];
          let credit = snapshot.val();
          credit = credit[key];
          firebase.database().ref(`credits/${key}`).update({status: 'closed'});
          const userRef = firebase.database().ref(`/members/`);
          console.log(credit.uid);
          userRef.orderByChild("uid").equalTo(credit.uid).once('value', (snapshot) => {
            if(snapshot.val()){
              let userkey = Object.keys(snapshot.val())[0];
              console.log(userkey);
              const userCreditRef = firebase.database().ref(`/members/${userkey}/credits/`);
              const credit = userCreditRef.orderByChild("credit").equalTo(hash).once('value', (snapshot) => {
                if(snapshot.val()){
                  let creditkey = Object.keys(snapshot.val())[0];
                  console.log(creditkey);
                  const credit = firebase.database().ref(`members/${userkey}/credits/${creditkey}`);
                  credit.remove();
                }
              });
              const userCheckinRef = firebase.database().ref(`members/${userkey}/checkins`);
              disloyalPlaces.map((place) => {
                console.log(userkey, place);
                const creditRef = firebase.database().ref(`members/${userkey}/checkins`);
                creditRef.orderByChild("pid").equalTo(place).once('value', (snapshot) => {
                  if(snapshot.val()){
                    let checkkey = Object.keys(snapshot.val())[0];
                    console.log(userkey,checkkey);
                    const checkin = firebase.database().ref(`members/${userkey}/checkins/${checkkey}`);
                    checkin.remove();
                    this.setState({ processed: true });
                  }
                });
              });
            }
          });
        }
      });
    }

    ScanQR = (() => {
      return (
        <div>
          <QrReader
            delay={this.state.delay}
            onError={this.handleError}
            onScan={this.handleScan}
            className={'qrreader'}
            />
        </div>
      )
    });

    ValidQR = (() => {
      const { checked, checking, credit, place, processed, user } = this.state;
      return (
        <div>
          {!processed &&
            checking &&
            !checked &&
            !credit &&
          <div>
            <img alt="valid code avatar" src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA1MDcuMiA1MDcuMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTA3LjIgNTA3LjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiPgo8Y2lyY2xlIHN0eWxlPSJmaWxsOiMzMkJBN0M7IiBjeD0iMjUzLjYiIGN5PSIyNTMuNiIgcj0iMjUzLjYiLz4KPHBhdGggc3R5bGU9ImZpbGw6IzBBQTA2RTsiIGQ9Ik0xODguOCwzNjhsMTMwLjQsMTMwLjRjMTA4LTI4LjgsMTg4LTEyNy4yLDE4OC0yNDQuOGMwLTIuNCwwLTQuOCwwLTcuMkw0MDQuOCwxNTJMMTg4LjgsMzY4eiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiNGRkZGRkY7IiBkPSJNMjYwLDMxMC40YzExLjIsMTEuMiwxMS4yLDMwLjQsMCw0MS42bC0yMy4yLDIzLjJjLTExLjIsMTEuMi0zMC40LDExLjItNDEuNiwwTDkzLjYsMjcyLjggICBjLTExLjItMTEuMi0xMS4yLTMwLjQsMC00MS42bDIzLjItMjMuMmMxMS4yLTExLjIsMzAuNC0xMS4yLDQxLjYsMEwyNjAsMzEwLjR6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojRkZGRkZGOyIgZD0iTTM0OC44LDEzMy42YzExLjItMTEuMiwzMC40LTExLjIsNDEuNiwwbDIzLjIsMjMuMmMxMS4yLDExLjIsMTEuMiwzMC40LDAsNDEuNmwtMTc2LDE3NS4yICAgYy0xMS4yLDExLjItMzAuNCwxMS4yLTQxLjYsMGwtMjMuMi0yMy4yYy0xMS4yLTExLjItMTEuMi0zMC40LDAtNDEuNkwzNDguOCwxMzMuNnoiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K" />
            <p>QR Code Valid</p>
            <div>
              <button
                className="btn"
                onClick={() => {this.processCredit()}}
                style={{width: 'auto'}}
              >
                <b>Process and Clear Credit</b>
              </button>
            </div>
          </div>}
          {!processed &&
            !checking &&
            checked &&
            credit &&
            credit.status === 'closed' &&
            <div>
              <p>
                <img src={fail} alt="Fail!" />
              </p>
              <div>Darn. Credit has been used ☹️</div>
          </div>}
          {!processed &&
            !checking &&
            checked &&
            !credit &&
            !place &&
            !user && <div>
              <p>
                <img src={fail} alt="Fail!" />
              </p>
              <div>Darn. Credit Invalid ☹️</div>
          </div>}
          {processed &&
            <div>
              <p>
                <img src={valid} alt="Success!" />
              </p>
              <div>
                <b>Credit Processed and Closed.</b><br />
                Customer's Card Cleared.
              </div>
          </div>}
        </div>
      )
    });

    render() {
        return (
          <div className='page-wrapper'>
            <h3>Validator</h3>
            <p>Please ask Barista for the secret QR code and scan below</p>
            <hr />
            {this.state.validURL ? this.ValidQR() : '' }
            {!this.state.validURL ? this.ScanQR() : ''}
          </div>
        );
    }
}

export default Validator;
