import React from "react";
import Async from 'react-promise';
import firebase from './firebase.js';
import { GeoFire } from 'geofire';
import geolib from 'geolib';
import { geolocated } from 'react-geolocated';

import { getPoints } from '../helpers/points.js';

/*
Humble Downtown: -LKXsfeTzOmVmHsEoWxD
Humble Lomas: -LKyK8Vq4Z6mqjbJo3-v
Little Bear: -LKyKDjkGtUb0PYMy4oU
Villa Myriam: -LKyKNoL1Yt8tN6y-5KO
The Brew: -LKyKYI0sPo_XYKMTJMD
Zendo: -LKyKgci1oueqrdngpKQ
Prismatic: -LKyKmgEGbiR2WPyYD4H
Rust Is Gold: -LiE1jvLzienlBxLsiDI
*/
const disloyalPlaces = [
'-LKXsfeTzOmVmHsEoWxD',
'-LKyK8Vq4Z6mqjbJo3-v',
'-LKyKDjkGtUb0PYMy4oU',
'-LKyKNoL1Yt8tN6y-5KO',
'-LKyKYI0sPo_XYKMTJMD',
'-LKyKgci1oueqrdngpKQ',
'-LKyKmgEGbiR2WPyYD4H',
'-LiE1jvLzienlBxLsiDI'
]

class GeoCheck extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedIn: false,
      setPoints: false,
      distance: '',
      hostPointsAdded: false,
      crewPointsAdded: false,
      status: false
    }
  }

  componentDidChange = () => {
    if(this.state.status === 'Checked-In! You got points!' ||
      this.state.status === 'Already Checked-In' ){
      setTimeout = (() => {
        this.setState({status: false});
      },3000);
    }
  }

  addCheckin = (userkey) => {
    if(this.props.hang.place){
      const placeRef = firebase.database().ref(`/places/`);
      placeRef.orderByChild("pid").equalTo(this.props.hang.place).once('value', (snapshot) => {
        let place = Object.keys(snapshot.val())[0];
        if (disloyalPlaces.indexOf(place) !== -1) {
          const usersCheckinRef = firebase.database().ref(`/members/${userkey}/checkins/`);
          usersCheckinRef.orderByChild("pid").equalTo(place).once('value', (snapshot) => {
            if (snapshot.exists()) {
              console.log('Already Visited');
              return;
            }else{
              usersCheckinRef.push({ pid: place });
              this.setState({status: 'Added a Crawl Location!'});
              console.log('Create a DisLoyal Visit');
              return;
            }
          });
        }
      });
    }
  }

  getLocale = (lat,lng,user,hangkey) => {

      let usersRef = firebase.database().ref('members');
      let usersGeoRef = firebase.database().ref('members-gl');
      let geoUser = new GeoFire(usersGeoRef);

      usersRef.orderByChild("uid").equalTo(user.uid).once('value', (snapshot) => {
        if (snapshot.exists()) {
          let key = Object.keys(snapshot.val())[0];
          let member = snapshot.val();
          member = member[key];
          geoUser.set(key, [lat, lng]).then(() => {
              let distance = geolib.getDistance(
                {latitude: lat, longitude: lng},
                {latitude: this.props.hang.lat, longitude: this.props.hang.lng}
              );
              if(distance < 100){
                const hangRef = firebase.database().ref(`/hangs/${hangkey}`);
                if(user.uid === this.props.hang.uid){
                  this.setState({status: 'Host Checking In...'});
                  hangRef.once('value', (snapshot) => {
                    if(snapshot.val()){
                      this.setState({checkedIn: true, setPoints: true});
                      this.props.setCheckLoc({chckloc: false});
                      const { hangKey, userkey } = this.props;
                      const hostPointsRef = firebase.database().ref(`/members/${userkey}/points/`);
                      const hostHangsRef = firebase.database().ref(`/members/${userkey}/hangs/`);
                      hostHangsRef.orderByChild("hang").equalTo(hangKey).once('value', (snapshot) => {
                        if (snapshot.exists()) {
                          console.log('Host already Checked-In');
                          this.setState({status: 'Already Checked-In'});
                          return;
                        }else{
                          if(!this.state.hostPointsAdded){
                            hangRef.update({validhost: true});
                            hostHangsRef.push({hang: hangKey});
                            let points = getPoints("host-checkin");
                            hostPointsRef.push(points);
                            console.log('Host Checking-In...');
                            this.setState({status: 'Checked-In! You got points!'});
                            this.setState({ hostPointsAdded: true });
                          }
                        }
                      });
                    }
                  });
                }else{
                  this.setState({status: 'Crew Member Checking In...'});
                  let userHangs = member.hangs;
                  let match = Object.entries(userHangs).find(obj => {
                    return obj[1].hang === hangkey;
                  });
                  const userHangRef = firebase.database().ref(`/members/${key}/hangs/${match[0]}`);
                  userHangRef.orderByChild("hang").equalTo('valid').once('value', (snapshot) => {
                    if (snapshot.exists()) {
                      console.log('Already Checked-In');
                      this.setState({status: 'Already Checked-In'});
                      return;
                    }else{
                      if(!this.state.crewPointsAdded){
                        hangRef.update({validcrew: true});
                        const { userkey } = this.props;
                        let points = getPoints(`checkin-${this.props.hang.visibility}`);
                        const crewPointsRef = firebase.database().ref(`/members/${key}/points/`);
                        crewPointsRef.push(points);
                        this.setState({ crewPointsAdded: true });
                        userHangRef.update({status: 'valid'});
                        this.setState({status: 'Checked-In! You got points!'});
                        this.addCheckin(userkey);
                        const usersRef = firebase.database().ref('members');
                        usersRef.orderByChild("uid").equalTo(this.props.hang.uid).once('value', (snapshot) => {
                          if (snapshot.exists()) {
                            var hostkey = Object.keys(snapshot.val())[0];
                            this.addCheckin(hostkey);
                          }
                        });
                        const hangCrewRef = firebase.database().ref(`/hangs/${hangkey}/crew`);
                        hangCrewRef.orderByChild("uid").equalTo(user.uid).once('value', (snapshot) => {
                          if (snapshot.exists()) {
                            var member = snapshot.val();
                            let memberKey = Object.keys(member)[0];
                            const hangCrewMemRef = firebase.database().ref(`/hangs/${hangkey}/crew/${memberKey}`);
                            hangCrewMemRef.update({valid: true});
                          }
                        });
                      }
                    }
                  });
                }
              }
            });
        }
      });
  }

  render() {
    return (
    <div className="center">
        { !this.props.isGeolocationAvailable
          ? <p>Your browser does not support Geolocation</p>
          : !this.props.isGeolocationEnabled
            ? <p>Geolocation is not enabled</p>
            : !this.props.coords
              ?
              <p>Checking location <i className="fa fa-circle-notch fa-spin"></i></p>
              : !this.state.checkedIn ?
                 <Async
                 promise={this.getLocale(this.props.coords.latitude,this.props.coords.longitude,this.props.user,this.props.hangKey)}
                />
                : ''
        }
        {/*this.state.status && <div>{this.state.status}</div>*/}
    </div>
    )
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
    maximumAge: 0,
    timeout: 10000,
  },
  watchPosition: true,
  userDecisionTimeout: 8000,
})(GeoCheck);
