import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import firebase from './firebase.js';

class AddName extends Component {
 state = {
   name: '',
   error: null,
 };
 render() {
   const { error } = this.state;
     return (
       <div className="page-wrapper AddName">
         <div>Wait a second...</div>
         <h3>What's your name, friend?</h3>
         <MuiThemeProvider>
           {error ? (
             <div>
               <span className="red">{error.message}</span>
             </div>
           ) : null}
           <form onSubmit={this.props.handleNameSubmit}>
            <div className="add-hang-wrapper">
               <TextField
                 type="text"
                 name="username"
                 placeholder="First Name + Last Name"
                 value={this.props.username}
                 onChange={this.props.handleChange}
               />
             </div>
             <button className="btn login">Save</button>
           </form>
         </MuiThemeProvider>
       </div>
   );
 }
}
export default AddName;
