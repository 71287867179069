import React from "react";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import firebase from './firebase.js';
import mmnt from 'moment';
import Moment from 'react-moment';

import fail from '../assets/fail.svg';
import valid from '../assets/validation.svg';

const disloyalPlaces = [
'-LiLz6MJUJSBhI4IdSel',
'-LKyKDjkGtUb0PYMy4oU',
'-LKyKmgEGbiR2WPyYD4H',
'-LkNMw6Fe_oOiKk5wNug',
'-LiE1jvLzienlBxLsiDI',
'-LKyKgci1oueqrdngpKQ',
'-Lg-ryjHaSRZfBc-ixfD',
]

class CheckCredit extends React.Component {
  constructor() {
    super();
    this.state = {
      credit: null,
      place: null,
      user: null,
      checking: true,
      checked: false,
      processed: false,
    }
  }

  componentDidMount = () => {
    const { hash } = this.props;
    const creditRef = firebase.database().ref(`/credits/`);
    creditRef.orderByChild("hash").equalTo(hash).once('value', (snapshot) => {
      if(snapshot.val()){
        let key = Object.keys(snapshot.val())[0];
        let credit = snapshot.val();
        credit = credit[key];
        this.setState({ credit });
        const placeRef = firebase.database().ref(`/places/`);
        placeRef.orderByChild("pid").equalTo(credit.pid).once('value', (snapshot) => {
          if(snapshot.val()){
            let placekey = Object.keys(snapshot.val())[0];
            let place = snapshot.val();
            place = place[placekey];
            this.setState({ place });
          }
        });
        const userRef = firebase.database().ref(`/members/`);
        userRef.orderByChild("uid").equalTo(credit.uid).once('value', (snapshot) => {
          if(snapshot.val()){
            let userkey = Object.keys(snapshot.val())[0];
            let user = snapshot.val();
            user = user[userkey];
            this.setState({ user });
          }
        });
      }
    });
    setTimeout(() => {
      this.setState({checked: true, checking: false});
    },3000);
  }

  processCredit = () => {
    const { hash } = this.props;
    const creditRef = firebase.database().ref(`/credits/`);
    creditRef.orderByChild("hash").equalTo(hash).once('value', (snapshot) => {
      if(snapshot.val()){
        let key = Object.keys(snapshot.val())[0];
        let credit = snapshot.val();
        credit = credit[key];
        firebase.database().ref(`credits/${key}`).update({status: 'closed'});
        const userRef = firebase.database().ref(`/members/`);
        console.log(credit.uid);
        userRef.orderByChild("uid").equalTo(credit.uid).once('value', (snapshot) => {
          if(snapshot.val()){
            let userkey = Object.keys(snapshot.val())[0];
            console.log(userkey);
            const userCreditRef = firebase.database().ref(`/members/${userkey}/credits/`);
            const credit = userCreditRef.orderByChild("credit").equalTo(hash).once('value', (snapshot) => {
              if(snapshot.val()){
                let creditkey = Object.keys(snapshot.val())[0];
                console.log(creditkey);
                const credit = firebase.database().ref(`members/${userkey}/credits/${creditkey}`);
                credit.remove();
              }
            });
            const userCheckinRef = firebase.database().ref(`members/${userkey}/checkins`);
            disloyalPlaces.map((place) => {
              console.log(userkey, place);
              const creditRef = firebase.database().ref(`members/${userkey}/checkins`);
              creditRef.orderByChild("pid").equalTo(place).once('value', (snapshot) => {
                if(snapshot.val()){
                  let checkkey = Object.keys(snapshot.val())[0];
                  console.log(userkey,checkkey);
                  const checkin = firebase.database().ref(`members/${userkey}/checkins/${checkkey}`);
                  checkin.remove();
                  this.setState({ processed: true });
                }
              });
            });
          }
        });
      }
    });
  }

  render() {
    const { checked, checking, credit, place, processed, user } = this.state;
    const { pid, uid } = this.props;

    return (
      <div className="page-wrapper">
        <h3>Credit Verification</h3>
        <hr />
        {checking &&
          <p>Checking <i className="fa fa-circle-notch fa-spin"></i></p>
        }
        {!processed &&
          !checking &&
          checked &&
          credit !== null &&
          credit.status === 'open' &&
          place !== null &&
          user !== null &&
          <div>
            <p>
              <img src={valid} alt="Success!" />
            </p>
            <div>Location: <b>{place.name}</b></div>
            <div>User: <b>{user.name}</b></div>
            {credit.datetime &&
            <div>Generated: <Moment format="MMMM, DD @ hh:mm a" className="hang-time">
              {credit.datetime}
            </Moment>
            <div className="center" style={{marginTop: '1rem'}}>
              <button className="btn" onClick={() => {this.processCredit()}}>
                <b>Process and Clear Credit</b>
              </button>
            </div>
            </div>}
        </div>}
        {!processed &&
          !checking &&
          checked &&
          credit &&
          credit.status === 'closed'&&
          <div>
            <p>
              <img src={fail} alt="Fail!" />
            </p>
            <div>Darn. Credit has been used ☹️</div>
        </div>}
        {!processed &&
          !checking &&
          checked &&
          !credit &&
          !place &&
          !user && <div>
            <p>
              <img src={fail} alt="Fail!" />
            </p>
            <div>Darn. Credit Invalid ☹️</div>
        </div>}
        {processed &&
          <div>
            <p>
              <img src={valid} alt="Success!" />
            </p>
            <div>
              Credit Processed and Closed.<br />
              Customer's Card Cleared.
            </div>
        </div>}
      </div>
    );
  }
}

export default CheckCredit;
