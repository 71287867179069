import React from "react";
import ReactGA from 'react-ga';
import firebase, {base} from './firebase.js';
import { GeoFire } from 'geofire';
import {Redirect, Route} from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Member from './member.js';
import MemberRow from './memberrow.js';
import _, { differenceWith, isEqual, orderBy, shuffle } from 'lodash';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

class Crew extends React.Component {
    constructor() {
      super();
      this.state = {
        crew: {},
        crewarr: [],
        diffnear: [],
        nearby: [],
      }
      this.removeCrewMember = this.removeCrewMember.bind(this);
    }

    removeCrewMember = ((key) => {
      console.log(key);
      ReactGA.event({category: 'User', action: 'Remove Crew Member'});
      base.remove(`/members/${this.props.userkey}/crew/${key}`);
    });

    handleShareButton(url) {
      ReactGA.event({category: 'User', action: 'Crew: Open Share'});
      this.props.openPopupBox(url);
    }

    memberShowNearby = (k) => {
      return new Promise((resolve, reject) => {
        const memberRef = firebase.database().ref(`/members/${k}`);
        let member = null;
        memberRef.once('value', (snapshot) => {
          if (snapshot.exists()) {
            let member = snapshot.val();
            if(typeof member.shownearby === 'undefined' || member.shownearby){
              resolve(true);
            }else{
              resolve(false);
            }
          }
        });
      });
    }

    componentDidMount(){
      if(this.props.uid){
        const usersRef = firebase.database().ref('members');
        usersRef.orderByChild("uid").equalTo(this.props.uid).once('value', (snapshot) => {
          if (snapshot.exists()) {
            var key = Object.keys(snapshot.val())[0];
            base.listenTo(`/members/${key}/crew/`, {
              context: this,
              then(crew){
                //crew = _.orderBy(crew, ['user'],['asc']);
                this.setState(prevState => ({
                  crew: crew,
                }));
                let crewarr = [];
                Object.entries(crew).map((c,i) => {
                  let member = c[1];
                  const usersRef = firebase.database().ref('members');
                  usersRef.orderByChild("uid").equalTo(member.uid).once('value', (snapshot) => {
                    if(snapshot.exists()){
                      var key = Object.keys(snapshot.val())[0];
                      crewarr.push(key);
                    }
                  });
                });
                this.setState(prevState => ({
                  crewarr: crewarr,
                }));
              }
            });
            // find nearby users
            let usersGeoRef = firebase.database().ref('members-gl');
            let geoUser = new GeoFire(usersGeoRef);
            let nearby = [];
            if(key){
              geoUser.get(key).then((location) => {
                  if (location === null) {
                      console.log("Provided key is not in GeoFire");
                  } else {
                    let geoQuery = geoUser.query({
                      center: location,
                      radius: 10
                    });
                    geoQuery.on("key_entered", (k, location, distance) => {
                      this.memberShowNearby(k).then(result => {
                        if(result === true &&
                         this.state.crewarr.indexOf(k) === -1 &&
                         k !== this.props.userkey){
                           this.setState({ nearby: [...this.state.nearby, k] });
                         }
                      });
                    });
                  }
              });
            }
          }
        });
      }
    }

    render() {
        if (!this.props.userkey || !this.props.uid || this.props.uid === '' || this.props.uid === null) {
           return <Redirect to='/' />
        }

        var baseUrl = window.location.protocol + "//" + window.location.host;
        var shareUrl = baseUrl+'/invite/user/'+this.props.userhash;

        let crew = this.state.crew;
        let Crew = Object.entries(crew).map((member,i) => {
          return (
            <MemberRow
              key={`member-${i}`}
              id={member[0]}
              member={member[1]}
              userkey={this.props.userkey}
              removeCrewMember={this.removeCrewMember}
            />
          )
        });

        var settings = {
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        };

        const { nearby } = this.state;

        return (
            <div className="page-wrapper">
              {nearby.length > 0 && <div>
              <div className="small">
                <strong> {nearby.length} </strong>
                potential <strong>
                Crew {this.state.nearcount === 1 ? 'Member' : 'Members'}
                </strong> nearby
              </div>
              <Slider {...settings} className="members-slider">
              {nearby && nearby.map((item, key) =>
                <Member
                  id={item}
                  key={key}
                  userkey={this.props.userkey}
                  setNearCount={this.setNearCount}
                />
              )}
              </Slider>
              <Route render={({history}) => (
                <span className="small underline"
                   onClick={() => {history.push('/profile/') }}
                >Don't want to appear above?</span>
              )}/>
              </div>}
              <hr />
              <p className="small">Earn <strong>Points</strong> and grow your <strong>Crew</strong> by inviting people to <strong>Hangerang</strong></p>
              <div className="share-buttons">
                <FacebookShareButton
                  url={shareUrl}
                  quote={'Join Me on Hangerang, A new app for getting together! '+ shareUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                  url={shareUrl}
                  title={'Join Me on Hangerang, A new app for getting together!'}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <EmailShareButton
                  url={shareUrl}
                  subject={'Join Me on Hangerang, A new app for getting together!'}
                  body={'Check it out:'+ shareUrl}
                >
                  <EmailIcon size={32} round />
                </EmailShareButton>
                <button
                  className="fa fa-link"
                  onClick={() => this.handleShareButton(shareUrl)}>
                </button>
              </div>
              <hr />
              <h3>Your Crew</h3>
              {this.state.crew ?
                <div className="crew">
                {Crew}
                </div>
              : <div className="center page-spinner">
              <i className="fa fa-circle-notch fa-spin"></i>
              </div>}
            </div>
        );
    }
}

export default Crew;
