import React from "react";
import ReactGA from 'react-ga';
import firebase, { base } from './firebase.js';
import {Redirect} from 'react-router-dom';
import Moment from 'react-moment';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';

class Order extends React.Component {
  constructor(props) {
    super(props);
      this.state = {}
      this.handleChange = this.handleChange.bind(this);
    }

    handleChange = (e) => {
      this.setState({
        [e.target.name]: e.target.value
      });
    }

    componentDidMount(){
      ReactGA.event({category: 'User', action: 'Looking at Order'});
      base.listenTo(`orders/${this.props.orderkey}`, {
        context: this,
        then(order){
          this.setState({date: order.date});
          base.bindToState(`items/${order.item}`, {
            context: this,
            state: 'item',
            }
          );
          base.bindToState(`members/${order.user}`, {
            context: this,
            state: 'user',
            }
          );
        }
      });
    }

    openPopupBox = (date,item,user) => {
      console.log(date,item,user)
      let content = (
        <div className={'card-popup'}>
          <i className={'fa fa-times'}
          onClick={()=>{PopupboxManager.close()}}>
          </i>
          <div className={'card-popup-content no-hor-padding'}>
            <b>{item.name}</b>
            <hr />
            <table className={'small'}>
              <tbody>
                <tr>
                  <td className={'right'}><b>User</b></td>
                  <td className={'left'}>{user.name}</td>
                </tr>
                <tr>
                  <td className={'right'}><b>Date</b></td>
                  <td className={'left'}><Moment format="MMM DD YYYY hh:mm a">{date}</Moment></td>
                </tr>
                <tr>
                  <td className={'right'}><b>Address</b></td>
                  <td className={'left'}>
                    {user.mailaddr.street1}
                    {user.mailaddr.street2}<br />
                    {user.mailaddr.city}, {user.mailaddr.state} {user.mailaddr.zip}
                  </td>
                </tr>
                <tr>
                  <td className={'right'}><b>Email</b></td>
                  <td className={'left'}>{user.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
      PopupboxManager.open({ content });
    }

    render() {
        const {
          date,
          item,
          user
        } = this.state;

        if(date && item && user){
          return (
            <tr>
                <td><strong>{item.name}</strong></td>
                <td><Moment format="MMM DD YYYY hh:mm a">{date}</Moment></td>
                <td>Pending</td>
                {this.props.admin &&
                  <td className="order-action">
                    <i
                      className="fa fa-info-circle"
                      onClick={() => {this.openPopupBox(date,item,user)}}>
                    </i>
                  </td>
                }
            </tr>
          );
        }else{
          return null;
        }
    }
}

export default Order;
