import React from "react";
import firebase, { base } from './firebase.js';
import FileUploader from "react-firebase-file-uploader";
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import EnhancedTextarea from 'material-ui/TextField/EnhancedTextarea';
import NativeSelect from '@material-ui/core/NativeSelect';
import DateTimePicker from 'material-ui-datetimepicker';
import DatePickerDialog from 'material-ui/DatePicker/DatePickerDialog'
import TimePickerDialog from 'material-ui/TimePicker/TimePickerDialog';
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import moment from 'moment';
import slugify from 'slugify';
import _, { orderBy } from 'lodash';

class HangEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: this.props.category,
      datetime: this.props.datetime,
      description: this.props.description,
      edit: false,
      imageURL: this.props.image,
      isUploading: false,
      progress: 0,
      showdesc: false
    }
  }

  handleChangeUsername = event =>
  this.setState({ username: event.target.value });
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = (error,task) => {
    this.setState({ isUploading: false });
    console.log(task);
    console.error("Upload error: "+error.message);
  };

  handleUploadSuccess = filename => {
    this.setState({ image: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ imageURL: url }));
  };

  componentDidMount() {
    if(this.props.crew){
      let crew = _.orderBy(this.props.crew, ['user'],['asc']);
      Object.entries(crew).map((c,i) => {
        let member = c[1];
        if(member.user){
          this.setState(prevState => ({
            options: [...prevState.options,
              {
                label: member.user,
                status: 'invited',
                user: member.user,
                uid: member.uid,
                userphoto: member.userphoto,
                value: slugify(member.user),
              }
            ]
          }));
          return null;
        }
      });
    }
  }

  setDate = (datetime) => {
    this.setState({ datetime });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  submitEdit = () => {
    console.log(this.state.datetime);
    const hangRef = firebase.database().ref(`/hangs/${this.props.hangkey}`);
    let category = this.state.category ? this.state.category : this.props.category;
    let visibility = this.state.visibility ? this.state.visibility : this.props.visibility;
    let updatedgroup = {};

    if(this.state.description){
      updatedgroup['description'] = this.state.description;
    }

    if(this.state.imageURL){
      updatedgroup['image'] = this.state.imageURL;
    }

    if(category){
      updatedgroup['category'] = category;
    }

    if(visibility){
      updatedgroup['visibility'] = visibility;
    }

    if(this.state.datetime){
      updatedgroup['datetime'] = this.state.datetime;
      updatedgroup['timestamp'] = parseInt(moment(this.state.datetime).format('x'));
    }

    hangRef.update(updatedgroup);
    this.props.toggleEdit();
  }

  deleteFile = () => {
    const storageRef = firebase.storage().ref();
    var deleteRef = storageRef.child('/images/'+this.props.hash+'.jpg');
    deleteRef.delete().then(() => {
      this.setState({ imageURL: '' });
      console.log('Image Deleted');
      const hangImgRef = firebase.database().ref(`/hangs/${this.props.hangkey}/image`);
      hangImgRef.remove();
    }).catch((error) => {
      this.setState({ imageURL: '' });
      console.log(error);
    });
  }

  render(){
      const {
        options,
        selectedOption,
      } = this.state;

      return (
        <MuiThemeProvider>
         <section>
            <div>
            <EnhancedTextarea
              className="input-desc"
              name="description"
              rows={3}
              placeholder={this.props.description ? this.props.description : "Description"}
              onChange={this.handleChange}
              value={this.state.description ? this.state.description : this.props.description}
            />
            <NativeSelect
              value={this.state.visibility ? this.state.visibility : this.props.visibility}
              onChange={this.handleChange}
              className={'category-select'}
              inputProps={{
                name: 'visibility',
                id: 'visibility',
              }}
            >
              <option value={'public'}>Public</option>
              <option value={'invite'}>Invite Only</option>
              <option value={'group'}>Group</option>
            </NativeSelect>
            <NativeSelect
              value={this.state.category ? this.state.category : this.props.category}
              onChange={this.handleChange}
              className={'category-select'}
              inputProps={{
                name: 'category',
                id: 'category',
              }}
            >
              <option value=''>Which Category?</option>
              <option value={'arts-crafts'}>Arts & Crafts</option>
              <option value={'food-drink'}>Food & Drink</option>
              <option value={'games-trivia'}>Games & Trivia</option>
              <option value={'help-giving'}>Help & Giving</option>
              <option value={'music-concerts'}>Music & Concerts</option>
              <option value={'sports-fitness'}>Sports & Fitness</option>
              <option value={'stage-screen'}>Stage & Screen</option>
              <option value={'trails-views'}>Trails & Views</option>
              <option value={'words-stories'}>Words & Stories</option>
            </NativeSelect>
            <DateTimePicker
              format='MMM DD, YYYY hh:mm A'
              className={"input-datetime"}
              name="datetime" placeholder="When?"
              onChange={this.setDate}
              DatePicker={DatePickerDialog}
              TimePicker={TimePickerDialog}
              timePickerDialogStyle={{height:'1vh'}}
              minutesStep={15}
              value={moment(this.state.datetime).format('ddd, MMMM Do YYYY, h:mm:ss a')}
            />
            <div className="clear center">
              <hr />
              {!this.state.imageURL && this.state.progress === 0 && <div>
                <label
                style={{
                  backgroundColor: '#fff',
                  color: '#000',
                  padding: 10,
                  borderRadius: '0.2rem',
                  border: '1px solid #000',
                  pointer: 'cursor',
                  textAlign: 'center',
                  display: 'inline-block',
                  fontSize: '0.8rem',
                  margin: '0 auto'
                }}>
                Upload a custom Image
              <FileUploader
                hidden
                accept="image/*"
                filename={this.props.hash}
                storageRef={firebase.storage().ref("images")}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                onProgress={this.handleProgress}
                maxWidth={600}
                maxHeight={600}
              />
              </label>
              <p className="small">
                Square images work best. At least 600px x 600px please. 🙂
              </p>
              </div>}
            {this.state.progress > 0 &&
             !this.state.imageURL &&
             <Progress percent={this.state.progress} />}
            {this.state.imageURL &&
              <div className="clear center">
                <div className="img-wrapper">
                  <img
                    src={this.state.imageURL}
                    ref="preview"
                    alt="image-clipper preview"
                  />
                  <i
                    className="fa fa-times delete-img"
                    onClick={() => { this.deleteFile() }}
                  ></i>
                </div>
              </div>
            }
            <hr />
            </div>
            <div className="clear button-row">
              <button className="btn center blue" onClick={() => this.submitEdit()}>Update</button>
              <button className="btn center pink" onClick={() => this.props.toggleEdit()}>Cancel</button>
            </div>
          </div>
         </section>
        </MuiThemeProvider>
      );
    }

}

export default HangEdit;
