import React, { Component } from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import firebase from './firebase.js';
import axios from 'axios';

//Actions
import { getPoints } from '../helpers/points.js';

class Register extends Component {
 state = {
   email: '',
   password: '',
   error: null,
 };
handleInputChange = (event) => {
   this.setState({ [event.target.name]: event.target.value });
 };
handleSubmit = (event) => {
   event.preventDefault();
   const { email, password } = this.state;
   console.log(email);
   axios.get('https://open.kickbox.io/v1/disposable/'+email).then((res) => {
     console.log(email.match(/^[\w,\d]{1}@[\w,\d]{1}.[\w,\d]+$/g));
    if(email.match(/^[\w,\d]{1}@[\w,\d]{1}.[\w,\d]+$/g) !== null &&
       email.match(/^[\w,\d]{1}@[\w,\d]{1}.[\w,\d]+$/g).length > 0){
      const msg = 'Really?! 😉';
      this.setState({ error: { ...this.state.error, message: msg} });
    }else if(res.data.disposable){
      const msg = 'Nice try. Please enter a real email address 😉';
      this.setState({ error: { ...this.state.error, message: msg} });
    }else{
      firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((user) => {
           //Do nothing
        })
        .catch((error) => {
          this.setState({ error: error });
       });
    }
   });
 };
 render() {
   const { email, password, error } = this.state;
   return (
     <div className="page-wrapper register">
       <MuiThemeProvider>
         {error ? (
           <div style={{padding: '0 2rem'}}>
             <span className="red">{error.message}</span>
           </div>
         ) : null}
         <form onSubmit={(e) => {this.handleSubmit(e)}}>
          <div className="add-hang-wrapper">
             <TextField
               type="text"
               name="email"
               placeholder="Email"
               value={email}
               onChange={this.handleInputChange}
             />
             <TextField
               type="password"
               name="password"
               placeholder="Password"
               value={password}
               onChange={this.handleInputChange}
             />
           </div>
           <button className="btn login">
           Register</button>
         </form>
         <div>
          Already registered? <a className='underline' onClick={() => {this.props.toggleReg();this.props.toggleLogin();}}>Sign In.</a>
         </div>
         <i className="fa fa-window-close close" onClick={() => {this.props.toggleReg()}}></i>
       </MuiThemeProvider>
     </div>
   );
 }
}
export default Register;
