import React from "react";
import ReactGA from 'react-ga';
import ReactDOM from 'react-dom';
import VisibilitySensor from 'react-visibility-sensor';
import geolib from 'geolib';
import fsm from 'fuzzy-string-matching';
import Rate from 'rc-rate';
import { PopupboxManager,PopupboxContainer } from 'react-popupbox';

import Dollar from '../assets/dollar.svg';

class Zcard extends React.Component {
  constructor() {
    super();
    this.state = {
      placeimg: false,
      distance: 0,
      open: null,
      location: {}
    }
    this.getPlace = this.getPlace.bind(this);
    this.placeCallback = this.placeCallback.bind(this);
    this.startHang = this.startHang.bind(this);
  }

  handleURL = (url) => {
    console.log(url);
    var win = window.open(url, '_blank');
    win.focus();
  }

  startHang = () => {
    setTimeout(() => {
      //console.log(this.state.location,this.props.item.name);
      this.props.toggleForm();
      this.props.setLocation(this.state.location);
      this.props.setName(this.props.item.name);
      this.props.setTitle(this.props.hangTitle);
      this.props.setCategory('food-drink');
    },500);
  }

  placeCallback = (results, status) => {
    const { item } = this.props;
    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
      let result;
      results.map(function(obj){
          let fuzzy = fsm(obj.name, item.name);
          if (fuzzy > 0.015) result = obj;
          return null;
      });
      if(result){
        this.setState({
          website: result.website,
          url: result.url,
          address: result.vicinity,
          location: {
            formatted_address: result.vicinity,
            place_id: result.place_id,
            geometry: result.geometry,
          }
        });
        console.log('Get photo for: '+item.name);
        if(result.photos && result.photos.length > 0){
          let placePhotoUrl = result.photos[0].getUrl({maxWidth:640});
          if(result.opening_hours){
            this.setState({
              open: result.opening_hours.open_now,
              placeimg: placePhotoUrl,
            });
          }
        }
      }
    }
  }

  getPlace = (lat,lng) => {
    let maploc = new window.google.maps.LatLng(lat,lng);
    let mapdom = ReactDOM.findDOMNode(this.refs.map);
    let map = new window.google.maps.Map(mapdom, {
        center: maploc,
        zoom: 20
      });
    let service = new window.google.maps.places.PlacesService(map);
    let request = {
      location: maploc,
      radius: '500',
      name: this.props.item.name
    };
    service.nearbySearch(request, this.placeCallback);
  }

  onChange = (isVisible) => {
    const { item, nth } = this.props;
    if(isVisible && !this.state.placeimg){
      //this.getPlace(item.location.latitude,item.location.longitude);
    }
  }

  openPopupbox = () => {
    const { item } = this.props;
    ReactGA.event({category: 'User', action: 'Zomato Popup Opened'});
    this.getPlace(item.location.latitude,item.location.longitude);
    setTimeout( () => {
      var Price = [];
      var i;
      for(i = 0; i < item.price_range; i++){
          Price.push(<img key={'dollar-'+i} src={Dollar} />);
      }
      let rating = 0;
      rating = item.user_rating.aggregate_rating;
      rating = parseInt(rating);
      let height = "300px";
      if( window.innerWidth < 414 ){
        height = "250px";
      }
      if( window.innerWidth < 375 ){
        height = "200px";
      }
      var Image = {
        backgroundImage: "url('"+this.state.placeimg+"')",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        overflow: "hidden",
        cursor: "pointer",
        height: height,
      }
      const content = (
        <div className={'card-popup'}>
          <i className={this.state.placeimg ? 'fa fa-times white' : 'fa fa-times'}
            onClick={()=>{PopupboxManager.close()}}>
          </i>
          {this.state.placeimg &&
          <div style={Image} className={'card-popup-header'}></div>}
          <div className={'card-popup-content'}>
            <h5>{item.name}</h5>
            <div className="small">{item.cuisines}</div>
            {this.state.url && <div>
              <i onClick={() => {this.handleURL(this.state.url)}} className={'fa fa-map-marker small'}></i>
            </div>}
            <div className="x-small">
              <strong>{item.location.address}</strong>
            </div>
            <div className="x-small">{this.state.distance}mi</div>
            {this.state.open !== null && <div className={this.state.open ? "small open-status green" : "small open-status red"}>
              {this.state.open ? 'Open Now' : 'Not Open Now'}
            </div>}
            {rating > 0 && rating &&
              <div>
                <Rate value={rating} allowHalf={true} />
              </div>
            }
            {item.price_range &&
              <div className="price">
                {Price}
              </div>
            }
          </div>
          <div
            className={'card-popup-bottom'}
            onClick={()=>{this.startHang(); PopupboxManager.close();}}
          >
            <div className={'card-popup-makehang'}>
              <strong>Hang</strong> Here
            </div>
          </div>
        </div>
      )
      PopupboxManager.open({ content });
    },1000);
  }

  componentDidMount = () => {
    const { item, lat, lng } = this.props;
    let distance = 1;
    if(item.location.latitude !== "0.0000000000" &&
       item.location.longitude !== "0.0000000000"){
      distance = geolib.getDistance(
        {latitude: lat, longitude: lng},
        {latitude: item.location.latitude, longitude: item.location.longitude}
      );
      distance = (distance/1000) * 0.621371;
      distance = distance.toFixed(1);
    }
    this.setState({distance});
  }

  render() {
    const { item } = this.props;

    var Image = {
      //backgroundImage: "url('"+this.state.placeimg+"')",
      backgroundColor: "#000",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      overflow: "hidden",
      height: "144px",
      borderRadius: "0.5rem",
      cursor: "pointer"
    }

    var Price = [];
    var i;
    for(i = 0; i < item.price_range; i++){
        Price.push(<img key={'dollar-'+i} src={Dollar} />);
    }

    let rating = 0;
    rating = item.user_rating.aggregate_rating;
    rating = parseInt(rating);

    return (
      <VisibilitySensor onChange={this.onChange}>
        <div>
          <div style={Image} onClick={this.openPopupbox}>
            <div id="map" ref={'map'} />
              <div className="content">
                <div><i className={`fa ${this.props.icon} white`}></i></div>
                <h4>{item.name}</h4>
                <div className="x-small">{this.state.distance}mi</div>
                {rating > 0 && rating &&
                  <div>
                    <Rate value={rating} allowHalf={true} />
                  </div>
                }
                {item.price_range &&
                <div>
                  {Price}
                </div>
                }
              </div>
            {this.state.placeimg && <span></span>}
          </div>
        </div>
      </ VisibilitySensor>
    );
  }
}

export default Zcard;
