import React, {Component} from 'react';

class Privacy extends Component {
  render() {
    return (
      <div className="page">
      <h1>Privacy Policy</h1>
      <p><strong>What information do we collect?</strong><br />

      <br />
      We collect information from you when you register on our site.<br />
      <br />
      When ordering or registering on our site, as appropriate, you may be asked to enter
      your: name, e-mail address, mailing address or phone number.<br />
      <br />
      <strong>What do we use your information for?</strong><br />
      <br />

      Any of the information we collect from you may be used in one of the following
      ways:<br />
      <br />
      To personalize your experience<br />
      (your information helps us to better respond to your individual needs)<br />
      <br />
      &#8226; To improve our website<br />

      (we continually strive to improve our website offerings based on the information and
      feedback we receive from you)<br />
      <br />
      &#8226; To process transactions<br />
      </p>
      <blockquote>
        Your information, whether public or private, will not be sold, exchanged,
        transferred, or given to any other company for any reason whatsoever, without your
        consent, other than for the express purpose of delivering the service requested.
      </blockquote><br />
      &#8226; To administer a contest, promotion, survey or other site feature<br />

      <br />
      <br />
      &#8226; To send periodic emails<br />
      <br />
      The email address you provide may be used to send you information, respond to
      inquiries, and/or other requests or questions.<br />
      <br />
      <strong>How do we protect your information?</strong><br />

      <br />
      We implement a variety of security measures to maintain the safety of your personal
      information when you place an order or enter, submit, or access your personal
      information.<br />
      <br />
      <strong>Do we use cookies?</strong><br />
      <br />
      Yes (Cookies are small files that a site or its service provider transfers to your
      computers hard drive through your Web browser (if you allow) that enables the sites
      or service providers systems to recognize your browser and capture and remember
      certain information<br />
      <br />

      We use cookies to understand and save your preferences for future visits and compile
      aggregate data about site traffic and site interaction so that we can offer better
      site experiences and tools in the future.<br />
      <br />
      <strong>Do we disclose any information to outside parties?</strong><br />
      <br />
      We do not sell, trade, or otherwise transfer to outside parties your personally
      identifiable information. This does not include trusted third parties who assist us
      in operating our website, conducting our business, or servicing you, so long as those
      parties agree to keep this information confidential. We may also release your
      information when we believe release is appropriate to comply with the law, enforce
      our site policies, or protect ours or others rights, property, or safety. However,
      non-personally identifiable visitor information may be provided to other parties for
      marketing, advertising, or other uses.<br />
      <br />
      <strong>California Online Privacy Protection Act Compliance</strong><br />

      <br />
      Because we value your privacy we have taken the necessary precautions to be in
      compliance with the California Online Privacy Protection Act. We therefore will not
      distribute your personal information to outside parties without your consent.<br />
      <br />
      As part of the California Online Privacy Protection Act, all users of our site may
      make any changes to their information at anytime by logging into their control panel
      and going to the 'Edit Profile' page.<br />
      <br />
      <strong>Childrens Online Privacy Protection Act Compliance</strong><br />
      <br />

      We are in compliance with the requirements of COPPA (Childrens Online Privacy
      Protection Act), we do not collect any information from anyone under 13 years of age.
      Our website, products and services are all directed to people who are at least 13
      years old or older.<br />
      <br />
      <strong>Online Privacy Policy Only</strong><br />
      <br />
      This online privacy policy applies only to information collected through our website
      and not to information collected offline.<br />
      <br />
      <strong>Terms and Conditions</strong><br />

      <br />
      Please also visit our Terms and Conditions section establishing the use, disclaimers,
      and limitations of liability governing the use of our website at <a href=
      "http://www.hangerang.com/terms-conditions">http://www.hangerang.com/terms-conditions</a><br />

      <br />
      <strong>Your Consent</strong><br />
      <br />
      By using our site, you consent to our privacy policy and terms and conditions.<br />

      <br />
      <strong>Changes to our Privacy Policy</strong><br />
      <br />
      If we decide to change our privacy policy, we will post those changes on this page,
      and/or send an email notifying you of any changes.<br />
      <br />
      <strong>Contacting Us</strong><br />
      <br />

      If there are any questions regarding this privacy policy you may contact us using the
      information below.<br />
      <br />
      http://www.hangerang.com<br />
      2936 Santa Monica Ave SE<br />
      Albuquerque, New Mexico 87106<br />
      United States<br />

      admin@hangerang.com<br />
      505-670-2545<br />
      </div>
    );
  }
}

export default Privacy;
