import React from "react";
import { base } from  './firebase.js';
import ButtonLink from './buttonlink.js';
import UserPhoto from './userphoto.js';

class HangCrew extends React.Component {

  render(){

      let hangtime = this.props.timestamp;
      let later = hangtime + 7200000;
      let date = new Date();
      let now = date.getTime();

      return (
        <div className="hang-crew-wrapper">
        {this.props.crew &&
          <ul className="hang-crew">
            {!this.props.validhost &&
              hangtime < now && now < later &&
              <div><p className="small grey">Waiting for Host Check-In...</p></div>
            }
            {this.props.validhost &&
              hangtime < now && now < later &&
              <div className="small grey" style={{marginBottom: '0.5rem'}}>
                Host has Checked-In!
              </div>
            }
            <span className="hang-crew-label">Crew</span>
            {Object.entries(this.props.crew).map((member) => {
              return (
              <li className="hang-crew-item" key={member[0]}>
                <span className="hang-member">
                  {member[1] &&
                    <UserPhoto
                      uid={member[1].uid}
                      email={member[1].email}
                      user={member[1].user}
                    />}
                  {member[1].user} {member[1].valid &&
                    <span>&nbsp;<i className="fa fa-check-circle green"></i></span>
                  }
                </span>
              </li>
              );
            })}
          </ul>
        }
        </div>
      );
    }

}

export default HangCrew;
