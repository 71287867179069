import React from "react";
import {Redirect, Route} from 'react-router-dom';

class Invite extends React.Component {
    constructor() {
      super();
      this.state = {
        timeout: false
      }
    }

    componentDidMount(){
      setTimeout(()=>{
        this.setState({timeout: true});
      },3000);
    }

    render() {
        if (this.state.timeout) {
           return <Redirect to='/' />
        }

        return (
            <div className="page-wrapper">
              <h1>Look at you! Getting Invited!</h1>
              <img
                style={{width: '300px', height: 'auto'}}
                src="https://media.giphy.com/media/ktwCnrcFnBRTO/giphy.gif"
                alt="You've been invited!" />
            </div>
        );
    }
}

export default Invite;
